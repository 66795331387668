import { timeClockClient, timeClockClientAsync } from "../adapters";

const SCHEDULE = "/v1/schedule";

export function getSchedule(scheduleId) {
  if (scheduleId) {
    return timeClockClient.get(`${SCHEDULE}/${scheduleId}`);
  } else {
    return timeClockClient.get(`${SCHEDULE}`);
  }
}

export function postSchedule(data) {
  return timeClockClient.post(`${SCHEDULE}`, data);
}

export function putSchedule(scheduleId, data) {
  return timeClockClient.put(`${SCHEDULE}/${scheduleId}`, data);
}

export function deleteSchedule(scheduleId) {
  return timeClockClient.delete(`${SCHEDULE}/${scheduleId}`);
}

export function getCheck(fromDate) {
  if (fromDate) {
    return timeClockClient.get(`/v1/check?fromDate=${fromDate}`);
  } else {
    return timeClockClient.get(`/v1/check`);
  }
}

export function requestSchedule(configuration) {
  const { data, method } = configuration;
  const id = data?._id ? `/${data?._id}` : "";
  const URLS = {
    GET: `${SCHEDULE}${id}`,
    POST: `${SCHEDULE}`,
    PUT: `${SCHEDULE}${id}`,
    DELETE: `${SCHEDULE}${id}`,
  };

  return timeClockClientAsync.request({ ...configuration, url: URLS[method] });
}
