import { useTranslation } from "react-i18next";
import { BasicTable, UIButton } from "components";
import { Grid, TableCell, Stack, CircularProgress, Box } from "@mui/material";
import { GroupsActions } from "../components";
import { useEffect, useState, useRef } from "react";

export const CreateGroups = ({
  setRefreshGroups,
  groupsData,
  permisos,
}) => {
  const { t } = useTranslation(["settings"]["general"]);
  const [groupRows, setGroupRows] = useState(null);
  const actionEditGroupRef = useRef(null);

  const getRows = () => {
    try {
      const rows = groupsData.map((item, i) => ({
        id: item._id,
        name: item.name,
        description: item.description,
        permissions: item.permits.length.toString(),
        acciones: (
          <GroupsActions
            permisos={permisos}
            groups={groupsData}
            setRefreshGroups={setRefreshGroups}
            registerFunction={(i === 0) ? (func) => (actionEditGroupRef.current = func) : null}
            row={{
              id: item._id,
              name: item.name,
              description: item.description,
              permissions: item.permits.length.toString(),
            }}
          />
        ),
      }));
      setGroupRows(rows);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if(groupsData && groupRows )
    {
      getRows();
    }
  }, [groupsData]);

  useEffect(() => {
    if (groupsData && (Array.isArray(permisos) && permisos.length > 0) && !groupRows) {
      getRows();
    }
  }, [permisos]);

  return (
    <>
      <Grid container spacing={3} justifyContent="center">
        {groupRows &&
          (
            <>
              <Grid item xs={12} md={10}>
                <Grid container justifyContent="flex-end">
                  <UIButton
                    disabled={(!!!groupRows || !!!groupsData)}
                    label={t("general:Nuevo")}
                    onClick={() => actionEditGroupRef.current && actionEditGroupRef.current()}
                    style={{ maxWidth: "150px", width: "auto", }}
                  />
                </Grid>
              </Grid>
              < Grid item xs={12} md={10}>
                <Box>
                  <BasicTable
                    rows={groupRows}
                    handleClick={() => { }}
                    hover={false}
                  >
                    <TableCell>{t("settings:NombreDelGrupo")}</TableCell>
                    <TableCell>{t("settings:DescripciónDelGrupo")}</TableCell>
                    <TableCell>{t("settings:NumeroDePermisos")}</TableCell>
                    <TableCell>{t("settings:Acciones")}</TableCell>
                  </BasicTable>
                </Box>
              </Grid>
            </>
          )
          ||
          <Stack
            justifyContent="center"
            alignItems="center"
            spacing={2}
            style={{ height: '70vh' }}
          >
            <CircularProgress />
          </Stack>
        }
      </Grid >
    </>
  );
};