// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.97.1/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.97.1/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.map-container {
  width: 100%;
  height: 50vh;
}

.combobox-input {
  width: 100%;
  padding: 10px;
  margin: 2rem 0;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  border: 1px solid #ccc;
  color: black;
}

.combobox-input:focus {
  outline: none;
  border-color: #007bff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.combobox-input:hover {
  border-color: #999;
}

.combobox-option {
  font-family: Arial, Helvetica, sans-serif;
  color: black;
}

.combobox-popover {
  margin-top: 1rem !important;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  z-index: 9999 !important;
}

.combobox {
  display: grid;
  grid-template-columns: auto 64px;
  gap: 2rem;
}

.clear-box {
  display: grid;
  place-content: center;
}

.clear-button {
  height: 37px;
}

.form-group-map {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  gap: 1rem;
  margin-top: 2rem;
}
`, "",{"version":3,"sources":["webpack://./src/app/components/Map/styles.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,WAAW;EACX,aAAa;EACb,cAAc;EACd,kBAAkB;EAClB,0BAA0B;EAC1B,uBAAuB;EACvB,sBAAsB;EACtB,qBAAqB;EACrB,sBAAsB;EACtB,YAAY;AACd;;AAEA;EACE,aAAa;EACb,qBAAqB;EACrB,gDAAgD;AAClD;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,yCAAyC;EACzC,YAAY;AACd;;AAEA;EACE,2BAA2B;EAC3B,kBAAkB;EAClB,0BAA0B;EAC1B,uBAAuB;EACvB,sBAAsB;EACtB,qBAAqB;EACrB,wBAAwB;AAC1B;;AAEA;EACE,aAAa;EACb,gCAAgC;EAChC,SAAS;AACX;;AAEA;EACE,aAAa;EACb,qBAAqB;AACvB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,aAAa;EACb,kCAAkC;EAClC,SAAS;EACT,gBAAgB;AAClB","sourcesContent":[".map-container {\n  width: 100%;\n  height: 50vh;\n}\n\n.combobox-input {\n  width: 100%;\n  padding: 10px;\n  margin: 2rem 0;\n  border-radius: 5px;\n  -webkit-border-radius: 5px;\n  -moz-border-radius: 5px;\n  -ms-border-radius: 5px;\n  -o-border-radius: 5px;\n  border: 1px solid #ccc;\n  color: black;\n}\n\n.combobox-input:focus {\n  outline: none;\n  border-color: #007bff;\n  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);\n}\n\n.combobox-input:hover {\n  border-color: #999;\n}\n\n.combobox-option {\n  font-family: Arial, Helvetica, sans-serif;\n  color: black;\n}\n\n.combobox-popover {\n  margin-top: 1rem !important;\n  border-radius: 5px;\n  -webkit-border-radius: 5px;\n  -moz-border-radius: 5px;\n  -ms-border-radius: 5px;\n  -o-border-radius: 5px;\n  z-index: 9999 !important;\n}\n\n.combobox {\n  display: grid;\n  grid-template-columns: auto 64px;\n  gap: 2rem;\n}\n\n.clear-box {\n  display: grid;\n  place-content: center;\n}\n\n.clear-button {\n  height: 37px;\n}\n\n.form-group-map {\n  display: grid;\n  grid-template-columns: 2fr 1fr 1fr;\n  gap: 1rem;\n  margin-top: 2rem;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
