import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  styled,
  Typography,
} from "@mui/material";
import {
  Close as CloseIcon,
  ExpandMore as ExpandMoreIcon,
} from "@mui/icons-material";
import { ConfirmDialog } from "components";
import { TypesOfPermits } from "../CreateRequests/TypesOfPermits";
import { VacationRequest } from "../CreateRequests/VacationRequest";
import { QuittingRequest } from "../CreateRequests/ContractEnd/QuittingRequest";
import { JobDescription } from "modules/Organization/JobDescription/JobDescription";
import { StaffRequest } from "modules/Recruitment/StaffRequests/StaffRequest";
import { JustifyForm } from "modules/ShiftAttendance/AttendanceReports/JustifyForm";
import { useTranslation } from "react-i18next";
import { useState, useRef, useEffect } from "react";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { DismissalRequest } from "../CreateRequests/ContractEnd/DismissalRequest";
dayjs.extend(relativeTime);

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const getAgo = (now, time) => {
  const ago = now.diff(time, "hour") < 24 ? time.from() : time.format("LLLL");

  return ago;
};

const TrackingSignersHistory = ({
  history,
  employees,
  noComments = "request:Pending",
}) => {
  const { t } = useTranslation(["request"]["general"]);
  const now = dayjs.tz();

  return (
    <List dense={true}>
      {history.map((h) => {
        const employee = employees[h.user];
        let text_secondary = "";

        if (!h.signed && !h.reject) {
          text_secondary = t(noComments);
        }

        if (h.signed) {
          const approvedAt = dayjs.tz(h.signed);
          const approvedAgo = getAgo(now, approvedAt);
          text_secondary = `${t("request:Approved")} ${approvedAgo}`;
        }

        if (h.comment) {
          text_secondary = h.comment;
        }

        if (h.reject) {
          const rejectAt = dayjs.tz(h.reject.rejectAt);
          const rejectAgo = getAgo(now, rejectAt);
          const rejectType =
            h.reject.type === "TEMPORAL"
              ? t("request:ChangesRequest")
              : t("request:Rejected");

          text_secondary = (
            <>
              <Typography
                component="span"
                variant="body2"
                sx={{ color: "text.primary", display: "block" }}
              >
                {h.reject.reason}
              </Typography>{" "}
              {rejectType} {rejectAgo}
            </>
          );
        }

        return (
          <ListItem key={h._id}>
            <ListItemAvatar>
              <Avatar src={employee.thumbnail} />
            </ListItemAvatar>
            <ListItemText
              primary={employee.fullName}
              secondary={text_secondary}
            />
          </ListItem>
        );
      })}
    </List>
  );
};

const TrackingSigners = ({ statusOfSigners, employees }) => {
  const { t } = useTranslation(["request"]["general"]);
  const settings = statusOfSigners?.settings?.flowSettings
    ? statusOfSigners?.settings?.flowSettings
    : statusOfSigners?.settings;
  let history = statusOfSigners?.signatureHistory
    ? _.filter(
        _.flatten(statusOfSigners?.signatureHistory),
        (h) => h.signed || h.reject,
      )
    : [];
  const signatures = statusOfSigners?.signatures
    ? _.map(_.values(statusOfSigners.signatures), (s) => {
        if (!s.reject && !s.signed) {
          s.comment = t("request:Pending");
        }
        return s;
      })
    : [];
  const approvers = settings.approvers
    ? _.map(
        _.filter(signatures, (i) => settings.approvers.includes(i.user)),
        ({ _id, user }) => ({ _id, user }),
      )
    : [];
  const authorizers = settings.authorizers
    ? _.filter(signatures, (i) => settings.authorizers.includes(i.user))
    : [];
  const [expanded, setExpanded] = useState(history.length ? "log" : "rules");
  const handleCollapse = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  history = _.concat(history, signatures).sort((a, b) => {
    const startDate = dayjs.tz(a.createdAt);
    const endDate = dayjs.tz(b.createdAt);

    return endDate.diff(startDate);
  });

  let text_approvers = t("request:NextPersonApprove");

  if (settings.approvers.length > 1) {
    switch (settings.authorizationMethod) {
      case "ALL":
        text_approvers = t("request:FollowingPeopleMustApprove");
        break;

      case "ONE":
        text_approvers = t("request:OneFollowingPeopleMustApprove");
        break;

      case "MAJORITY":
        text_approvers = t("request:MajorityFollowingPeopleMustApprove");
        break;
    }
  }

  return (
    <>
      <Accordion
        expanded={expanded === "rules"}
        onChange={handleCollapse("rules")}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>{t("request:Rules")}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>{text_approvers}</Typography>
          <TrackingSignersHistory
            employees={employees}
            history={approvers}
            noComments=""
          />
          {statusOfSigners.settings.finalAuthorizer ? (
            <>
              <Typography>
                {t("request:OneFollowingPeopleMustAuthorize")}
              </Typography>
              <TrackingSignersHistory
                employees={employees}
                history={authorizers}
              />
            </>
          ) : (
            ""
          )}
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === "log"} onChange={handleCollapse("log")}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>{t("request:Log")}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <TrackingSignersHistory
            employees={employees}
            history={history}
            noComments="request:NoComments"
          />
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export const RequestModal = ({
  open,
  setOpen,
  typeDocument,
  request,
  application = "organization",
  employees,
  readOnly = false,
  history = false,
}) => {
  const { t } = useTranslation(["request"]);
  const statusOfSigners = request?.statusOfSigners;

  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const onConfirmRef = useRef(null);
  const onCancelRef = useRef(() => setOpen(true));
  useEffect(() => {
    if (onConfirmRef.current !== null) {
      setOpenConfirmDialog(true);
    }
  }, [onConfirmRef.current]);

  return (
    <>
      <ConfirmDialog
        open={openConfirmDialog}
        setOpen={setOpenConfirmDialog}
        onConfirm={async () => await onConfirmRef.current()}
        onCancel={() => onCancelRef.current()}
      />
      <BootstrapDialog open={open} maxWidth="lg" fullWidth={true}>
        <DialogTitle sx={{ m: 0, p: 2 }}>
          {typeDocument === "permissions" && t("request:Solicituddepermisos")}
          {typeDocument === "vacation" && t("request:Solicituddevacaciones")}
          {typeDocument === "jobPosition" && t("request:ApplicationNewJob")}
          {typeDocument === "staff" && t("request:SolicitudAumentoPuestos")}
          {typeDocument === "quitting" && t("request:RequestQuitting")}
          {typeDocument === "termination" && t("request:RequestDismissal")}
          {request ? ` - ${t("recruitment:Folio")} ${request.folio}` : ""}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => setOpen(false)}
          sx={(theme) => ({
            position: "absolute",
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
          })}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Grid container spacing={1}>
            <Grid item={true} md={8} xs={12}>
              {typeDocument === "permissions" && (
                <TypesOfPermits
                  onConfirmRef={onConfirmRef}
                  request={request}
                  application={application}
                  setOpen={setOpen}
                  readOnly={readOnly}
                />
              )}
              {typeDocument === "vacation" && (
                <VacationRequest
                  onConfirmRef={onConfirmRef}
                  request={request}
                  application={application}
                  setOpen={setOpen}
                  readOnly={readOnly}
                  history={history}
                />
              )}
              {typeDocument === "jobPosition" && (
                <JobDescription
                  application={application}
                  setOpen={setOpen}
                  readOnly={readOnly}
                />
              )}
              {typeDocument === "justifications" && (
                <JustifyForm
                  justificationRequest={request}
                  application={application}
                  setOpen={setOpen}
                  readOnly={readOnly}
                />
              )}
              {typeDocument === "staff" && (
                <StaffRequest
                  application={application}
                  btnBack={false}
                  setOpen={setOpen}
                  readOnly={readOnly}
                />
              )}
              {typeDocument === "quitting" && (
                <QuittingRequest
                  onConfirmRef={onConfirmRef}
                  request={request}
                  application={application}
                  setOpen={setOpen}
                  readOnly={readOnly}
                />
              )}
              {typeDocument === "termination" && (
                <DismissalRequest
                  onConfirmRef={onConfirmRef}
                  request={request}
                  application={application}
                  setOpen={setOpen}
                  readOnly={readOnly}
                />
              )}
            </Grid>
            <Grid item={true} md={4} xs={12}>
              {statusOfSigners && (
                <TrackingSigners
                  statusOfSigners={statusOfSigners}
                  employees={employees}
                />
              )}
            </Grid>
          </Grid>
        </DialogContent>
      </BootstrapDialog>
    </>
  );
};

export const NewRequestModal = ({
  open,
  setOpen,
  application = "organization",
  typeDocument = "",
  saveInRedux = true,
  employee = null,
}) => {
  const { t } = useTranslation(["request"]);

  return (
    <BootstrapDialog open={open} maxWidth="lg" fullWidth={true}>
      <DialogTitle sx={{ m: 0, p: 2 }}>
        {typeDocument === "permissions" && t("request:Solicituddepermisos")}
        {typeDocument === "vacation" && t("request:Solicituddevacaciones")}
        {typeDocument === "jobPosition" && t("request:ApplicationNewJob")}
        {typeDocument === "staff" && t("request:SolicitudAumentoPuestos")}
        {typeDocument === "quitting" && t("request:RequestQuitting")}
        {typeDocument === "termination" && t("request:RequestDismissal")}
        {typeDocument === "kicked_out" && t("request:ImmediateDismissal")}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={() => setOpen(false)}
        sx={(theme) => ({
          position: "absolute",
          right: 8,
          top: 8,
          color: theme.palette.grey[500],
        })}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers>
        {typeDocument === "permissions" && (
          <TypesOfPermits application={application} setOpen={setOpen} />
        )}
        {typeDocument === "vacation" && (
          <VacationRequest
            application={application}
            setOpen={setOpen}
            saveInRedux={saveInRedux}
          />
        )}
        {typeDocument === "jobPosition" && (
          <JobDescription
            application={application}
            setOpen={setOpen}
            saveInRedux={saveInRedux}
          />
        )}
        {typeDocument === "staff" && (
          <StaffRequest
            application={application}
            btnBack={false}
            setOpen={setOpen}
          />
        )}
        {typeDocument === "quitting" && (
          <QuittingRequest
            application={application}
            setOpen={setOpen}
            saveInRedux={saveInRedux}
          />
        )}
        {(typeDocument === "termination" || typeDocument === "kicked_out") && (
          <DismissalRequest
            application={application}
            setOpen={setOpen}
            saveInRedux={saveInRedux}
            employee={employee}
            typeDocument={typeDocument}
          />
        )}
      </DialogContent>
    </BootstrapDialog>
  );
};
