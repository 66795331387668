import { useRequests } from "hooks";
import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { authUserSelect } from "@redux/slices/authUser";
import { useTranslation } from "react-i18next";
import { getFiles, getTerminations } from "api";
import { Alert, Box, CircularProgress, Grid, Stack } from "@mui/material";
import { tenantSettingsSelect } from "@redux/slices/tenant";
import { Form, Formik } from "formik";
import {
  UIButton,
  UIDatePickerFast,
  UIJoditEditor,
  UISelectFast,
  UISwitchFast,
  Toast,
} from "components";
import { MultipleFiles } from "./MultipleFiles";
import * as Yup from "yup";
import { deleteContractEnd, postContractEnd, putContractEnd } from "api";
import { setRequestUpdated } from "@redux/slices/requests";
import { useNavigate } from "react-router-dom";

import { ConfirmDialog } from "components";

export const DismissalRequest = ({
  onConfirmRef = null,
  setOpen = null,
  request = null,
  readOnly = false,
  saveInRedux = true,
  employee = "",
  typeDocument = "termination",
}) => {
  const { t } = useTranslation();
  const { user } = useSelector(authUserSelect);
  const { employees } = useRequests();
  const { tenant } = useSelector(tenantSettingsSelect);
  const [supervises, setSupervies] = useState([]);
  const [terminations, setTerminations] = useState({});
  const [terminationsOptions, setTerminationsOptions] = useState([]);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const values = request
    ? request
    : {
        status: "PENDING",
        type: typeDocument.toUpperCase(),
        employee: employee,
        reason: "",
        otherReason: "",
        documents: [],
        dateOfDeparture: "",
        applyForRehire: false,
        commentsForNotRehiring: "",
      };
  readOnly = readOnly ? readOnly : request?.status === "PENDING";

  const [finish, setFinish] = useState(false);
  const [files, setFiles] = useState([]);

  const getSupervises = (employees, supervisor, supervises) => {
    const supervisesNextLevel = _.filter(
      employees,
      (e) => e.supervisor === supervisor.fullName,
    );

    if (supervisesNextLevel.length) {
      supervises = _.reduce(
        supervisesNextLevel,
        (result, s, n) => {
          return getSupervises(employees, s, result);
        },
        supervises,
      );
    }

    return [...supervises, ...supervisesNextLevel];
  };

  useEffect(() => {
    const getData = async () => {
      let supervisesFilter = [];
      const supervisor = employees[user._id];
      const { data: terminationsData } = await getTerminations();

      if (request?.employee) {
        supervisesFilter = _.filter(employees, "_id", request.employee);
      } else if (typeDocument === "kicked_out") {
        console.log("employee => ", employee);
        console.log("typeDocument => ", typeDocument);
        supervisesFilter = _.filter(employees, { _id: employee });
        console.log("supervisesFilter => ", supervisesFilter);
      } else {
        supervisesFilter = getSupervises(employees, supervisor, []);
      }

      const filesData = [];
      if (request) {
        for (const f of request.documents) {
          const { data: d } = await getFiles(tenant._id, f);
          filesData.push(d.content);
        }
      }

      setFiles(filesData);
      setSupervies(
        supervisesFilter.map((e) => ({
          value: e._id,
          label: e.fullName,
        })),
      );
      setTerminations(
        _.reduce(
          terminationsData,
          (result, value) => {
            result[value._id] = value;
            return result;
          },
          {},
        ),
      );

      let reasonsOptions = [
        ..._.map(_.filter(terminationsData, "isActive", true), (t) => ({
          value: t._id,
          label: t.reason,
        })),
        { value: "OTHER", label: t("request:OTHER") },
      ];
      setTerminationsOptions(reasonsOptions);
      setFinish(true);
    };

    if (employees) {
      getData();
    }
  }, [employees]);

  const onSubmit = async (values) => {
    let message = "";

    try {
      if (values._id) {
        const { data } = await putContractEnd(values);

        message = t("request:DismissalSuccessfullyUpdate");
        if (saveInRedux) {
          dispatch(
            setRequestUpdated({
              type: "termination",
              action: "update",
              data: data,
            }),
          );
        }
      } else {
        const { data } = await postContractEnd(values);
        message = t("request:DismissalSuccessfullyCreated");
        if (saveInRedux) {
          dispatch(
            setRequestUpdated({
              type: "termination",
              action: "add",
              data: data,
            }),
          );
        }
      }

      setOpen(false);
      Toast.fire({ icon: "success", title: message });

      if (typeDocument === "kicked_out") {
        navigate("/cereza-organization/employees/profiles");
      }
    } catch (err) {
      console.log(err);
      Toast.fire({
        icon: "error",
        title: err?.response?.data?.message
          ? err?.response?.data?.message
          : t("request:DismissalError"),
      });
    }
  };

  const deleteItem = async (item) => {
    try {
      await deleteContractEnd(item._id);
      if (saveInRedux) {
        dispatch(
          setRequestUpdated({
            type: "termination",
            action: "delete",
            data: item,
          }),
        );
      }
      setOpen(false);
      Toast.fire({
        icon: "success",
        title: t("request:DismissalSuccessfullyDelete"),
      });
    } catch (err) {
      Toast.fire({
        icon: "error",
        title: err?.response?.data?.message
          ? err?.response?.data?.message
          : t("request:DismissalErrorDelete"),
      });
    }
  };

  return finish ? (
    <>
      <Formik
        initialValues={values}
        onSubmit={onSubmit}
        validationSchema={Yup.object().shape({
          otherReason: Yup.string().required(t("settings:isRequired")),
          commentsForNotRehiring: Yup.string().when("applyForRehire", {
            is: true,
            then: (schema) => schema.required(t("settings:isRequired")),
          }),
        })}
      >
        {(formik) => (
          <Form autoComplete="off">
            {typeDocument === "kicked_out" && (
              <Box mb={2}>
                <Alert severity="warning">
                  {t("request:AlertImmediateDismissal")}
                </Alert>
              </Box>
            )}
            <Grid container={true} spacing={2} mb={2}>
              <Grid item={true} xs={12} md={6}>
                <UISelectFast
                  label={t("request:Employee")}
                  name="employee"
                  options={supervises}
                  required={true}
                  disabled={
                    formik.isSubmitting ||
                    readOnly ||
                    typeDocument === "kicked_out"
                  }
                />
              </Grid>
            </Grid>
            <Grid container={true} spacing={2}>
              <Grid item={true} xs={12} md={6}>
                <UISelectFast
                  label={t("request:Reason")}
                  name="reason"
                  required={true}
                  options={terminationsOptions}
                  disabled={formik.isSubmitting || readOnly}
                  onChange={(e, newValue) => {
                    const { value } = newValue;

                    if (value === "OTHER") {
                      formik.setFieldValue("otherReason", "");
                    } else {
                      formik.setFieldValue(
                        "otherReason",
                        terminations[value].description,
                      );
                    }

                    formik.setFieldValue("reason", value);
                  }}
                />
              </Grid>
              <Grid item={true} xs={12} md={3}>
                <UIDatePickerFast
                  name={"dateOfDeparture"}
                  label={t("request:dateOfDeparture")}
                  disabled={formik.isSubmitting || readOnly}
                />
              </Grid>
              <Grid item={true} xs={12} md={12}>
                <UIJoditEditor
                  name={"otherReason"}
                  label={t("request:ReasonDescriptionOrOhter")}
                  disabled={formik.isSubmitting || readOnly}
                />
              </Grid>
              <Grid item={true} xs={12} md={12}>
                <MultipleFiles
                  formik={formik}
                  name="documents"
                  disabled={formik.isSubmitting || readOnly}
                  files={files}
                  setFiles={setFiles}
                />
              </Grid>
              <Grid item={true}>
                <UISwitchFast
                  label={t("request:ApplyForRehire")}
                  name={"applyForRehire"}
                  disabled={formik.isSubmitting || readOnly}
                />
              </Grid>
              <Grid item={true} xs={12} md={12}>
                <UIJoditEditor
                  name={"commentsForNotRehiring"}
                  label={t("request:CommentsForNotRehiring")}
                  disabled={formik.isSubmitting || readOnly}
                />
              </Grid>
            </Grid>
            {!readOnly && (
              <Stack
                mt={2}
                direction={{ xs: "column", sm: "row" }}
                spacing={{ xs: 1, sm: 1, md: 1 }}
              >
                <UIButton
                  type="submit"
                  label={
                    formik?.values?._id
                      ? t("general:Actualizar")
                      : ["PENDING", "REVIEW_REQUIRED"].includes(
                            formik.values?.status,
                          )
                        ? t("general:Request")
                        : t("general:Guardar")
                  }
                  loading={formik.isSubmitting}
                  disabled={formik.isSubmitting}
                  fullWidth={false}
                />
                {formik.values?.status === "REVIEW_REQUIRED" && (
                  <UIButton
                    label={t("general:Eliminar")}
                    onClick={() => {
                      onConfirmRef.current = async () => {
                        formik.setSubmitting(true);
                        await deleteItem(formik.values);
                      }
                      setOpen(false);
                    }}
                    disabled={formik.isSubmitting}
                    fullWidth={false}
                  />
                )}
              </Stack>
            )}
          </Form>
        )}
      </Formik>
    </>
  ) : (
    <Stack
      direction="row"
      justifyContent="center"
      alignItems="center"
      spacing={2}
    >
      <CircularProgress />
    </Stack>
  );
};
