import { UIBootstrapDialog } from "components";
import { useTranslation } from "react-i18next";
import { Tabs, Tab, DialogContent, Typography } from "@mui/material";
import { Organization } from "../Organization";
import { Employee } from "../Employee";
import { useAccess } from "hooks";
import { useEffect, useState } from "react";

export const EditGroup = ({
  openEditGroup,
  setOpenEditGroup,

  permisos,
  setRefreshGroups,

  valueForm,
  setValueForm,

  groupSelected,
  setGroupSelected,
}) => {
  const { t } = useTranslation(["settings"]["general"]);

  const { HasPermissions } = useAccess();

  const { canAdd, canChange, canDelete } = HasPermissions(
    "/cereza-organization/settings/security-groups"
  );

  const handleChangeForm = (event, newValue) => {
    setValueForm(newValue);
  };
  return (
    <UIBootstrapDialog open={openEditGroup} setOpen={setOpenEditGroup} width="70%"
      title={(!groupSelected) ? t("settings:CrearUnGrupoDePermisos") : t("settings:EditarUnGrupoDePermisos")}
    >
      <DialogContent>
        <>
          {groupSelected === null && (
            <>
              <Tabs value={valueForm} onChange={handleChangeForm}>
                <Tab
                  value="one"
                  label={t("settings:Organization")}
                  onClick={() => setGroupSelected(null)}
                />
                <Tab
                  value="two"
                  label={t("settings:Employee")}
                  onClick={() => setGroupSelected(null)}
                />
              </Tabs>
              <Typography variant="caption">
                {t("settings:CategoriaAlert")}
              </Typography>
            </>
          )}
          <br />
          {valueForm === "one" && (
            <Organization
              groupSelected={groupSelected}
              setGroupSelected={setGroupSelected}
              permisos={permisos}
              setRefreshGroups={setRefreshGroups}
              canAdd={canAdd}
              canChange={canChange}
              canDelete={canDelete}
            />
          )}
          {valueForm === "two" && (
            <Employee
              groupSelected={groupSelected}
              setGroupSelected={setGroupSelected}
              permisos={permisos}
              setRefreshGroups={setRefreshGroups}
              canAdd={canAdd}
              canChange={canChange}
              canDelete={canDelete}
            />
          )}
        </>
      </DialogContent>
    </UIBootstrapDialog>
  );

};
