import React, { useRef, useState } from "react";
import { Button } from "@mui/material";

export const EmployeeButton =  ({ employeeName, onShortClick, onLongClick }) => {
  const timerRef = useRef(null); 
  const isLongPress = useRef(false);
  const [disableButton, setDisableButton] = useState(false);
  const longPressThreshold = 250; 

  const handleMouseDown = () => {
    isLongPress.current = false; 
    timerRef.current = setTimeout(() => {
      isLongPress.current = true;
      if (onLongClick) {
        onLongClick();
      }
    }, longPressThreshold);
  };

  const handleMouseUpOrLeave = () => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
      timerRef.current = null;

      if (!isLongPress.current && onShortClick) {
        setDisableButton(true);
        onShortClick();
      }
    }
  };

  return (
    <Button
      disabled={disableButton}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUpOrLeave}
      size="small"
    >
      {employeeName}
    </Button>
  );
};
