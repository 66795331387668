import i18n from "i18next";
import Backend from "i18next-http-backend";
import HttpApi from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

i18n
  .use(Backend)
  .use(HttpApi)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    supportedLngs: ["es", "en", "fr", "pt"],
    backend: {
      loadPath: "/translate/{{ns}}/{{lng}}.json",
    },
    fallbackLng: "es",
    debug: false,
    detection: {
      order: ["path", "cookie", "htmlTag"],
      caches: ["cookie"],
    },
    ns: [
      "assets",
      "forms",
      "employee",
      "general",
      "help",
      "home",
      "learningCenter",
      "options",
      "organization",
      "performanceCenter",
      "recruitment",
      "resources",
      "shiftAttendance",
      "sidenav",
      "tools",
      "settings",
      "request",
    ],
    interpolation: {
      espaceValue: false,
      formatSeparator: ",",
    },
    react: {
      useSuspense: true,
    },
  });

export default i18n;
