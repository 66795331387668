import React, {  useEffect, useRef, useState } from "react";
import { PropTypes, string } from "prop-types";
import {
  Avatar,
  IconButton,
  Stack,
  ImageListItem,
  ImageListItemBar,
  Box
} from "@mui/material";
import {
  CameraAlt as CameraAltIcon,
  CloudUpload as CloudUploadIcon,
  ZoomOutMap as ZoomOutMapIcon,
  DriveFileRenameOutline as EditIcon,
} from "@mui/icons-material";
import { AvatarCropper } from "../AvatarCropper";
import { useSelector } from "react-redux";
import { tenantSettingsSelect } from "@redux/slices/tenant";
import { getFiles } from "api/integrations";
import "react-image-crop/dist/ReactCrop.css";
import { PreviewModal } from "./PreviewModal";
import { CameraModal } from "./CameraModal";
import { ImageCrop } from "./ImageCrop";
import { FileThumbnail } from "./FileThumbnail";

const getBase64 = (file) => {
  return new Promise((resolve) => {
    let reader = new FileReader();

    reader.readAsDataURL(file);

    reader.onload = () => {
      resolve(reader.result);
    };
  });
};

export const FilePreview = ({
  src,
  setSrc,
  lblBtnUpdate,
  required = false,
  srcPath = null,
  formik = null,
  name = null,
  isAvatar = false,
  // BtnOpenAlt = null,
  disabled = false,
  acceptFile = null,
}) => {
  const { tenant } = useSelector(tenantSettingsSelect);
  const [loading, setLoading] = useState(false);
  const disabledCondition = disabled ||  loading;
  useEffect(() => {
    const fetchSource = async () => {
      let source = srcPath ? srcPath : formik.values[name];

      if (source && !/data:/.test(source)) {
        setLoading(true);
        try {
          const { data } = await getFiles(tenant._id, source);
          setSrc(data.content);
        } catch (err) {
          console.error("Error getting photo: ", err);
          setSrc(null);
        }
        finally
        {
          setLoading(false);
        }
      } else {
        setSrc(null);
      }
    };

    fetchSource();
  }, [srcPath]);

  useEffect(() => {
    let formikValue = formik.values[name];
    if((typeof formikValue === "string" &&  formikValue.length < 1))
    {
      setSrc(null);
    }
  }, [formik.values[name]]);

  const [openZoom, setOpenZoom] = useState(false);
  const inputFile = useRef(null);

  const handleOpenZoom = () => {
    setOpenZoom(true);
  };

  const [openUpload, setOpenUpload] = useState(false);

  const handleOpenUpload = () => {
    if (isAvatar) {
      setOpenUpload(true);
    } else {
      inputFile.current.click();
    }
  };

  const handleFileInputChange = (e) => {
    getBase64(e.target.files[0])
      .then((result) => {
        let file = result;
        var data_type = file.split(";")[0];
        switch (data_type) {
          case "data:image/jpeg":
          case "data:image/png":
            setSrcRef(file);
            handleOpenImageCrop();
            break;
          default:
            setSrc(file);
            formik.setFieldValue(name, file);
            break
        }
        inputFile.current.value = "";
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [openCamera, setOpenCamera] = useState(false);

  const handleOpenCamera = () => {
    setOpenCamera(true);
  };

  const [srcRef, setSrcRef] = useState(null);
  const [openImageCrop, setOpenImageCrop] = useState(false);
  const handleOpenImageCrop = () => {
    setOpenImageCrop(true);
  };

  return (
    <>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="center"
      >
        <ImageListItem sx={
          {
            minWidth: "193px",
            maxWidth: "min-content",
            minHeight: "193px",
            '&:hover .MuiImageListItemBar-root': {
              opacity: 1,
            },
          }}>
          {
            isAvatar
              ?
              <Avatar
                alt="preview"
                src={src}
                sx={{
                  width: "auto",
                  height: "193px",
                }}
              />
              :
              <FileThumbnail src={src} />
          }
          
          <ImageListItemBar
            sx={
              {...(isAvatar && {
                opacity: 0,
                transition: 'opacity 0.3s ease-in-out',
                background: 'rgba(0,0,0,0.6)',})
            }}
            actionIcon={
              <Box display="flex" alignItems="center"
              >
                <IconButton
                  onClick={handleOpenZoom}
                  sx={{ color: "rgba(255, 255, 255, 0.75)" }}
                  disabled={disabledCondition || typeof src !== "string" || src.length < 1}
                >
                  <ZoomOutMapIcon />
                </IconButton>
              </Box>
            }
          ></ImageListItemBar>
          <ImageListItemBar
            sx={{
              background: "rgba(0,0,0,0)",
              width: "50%",

              ...(isAvatar &&{
                opacity: 0,
                transition: 'opacity 0.3s ease-in-out',})
            }}
            actionPosition="left"
            actionIcon={
              <Box display="flex" alignItems="center" >
                <IconButton
                  onClick={handleOpenCamera}
                  sx={{ color: "rgba(255, 255, 255, 0.75)", paddingRight: "3px" }}
                  disabled={disabledCondition}
                >
                  <CameraAltIcon />
                </IconButton>
                <IconButton
                  onClick={handleOpenUpload}
                  sx={{ color: "rgba(255, 255, 255, 0.75)", paddingLeft: "3px" }}
                  disabled={disabledCondition}
                >
                  {
                    typeof src === "string"
                      ?
                      <EditIcon />
                      :
                      <CloudUploadIcon />
                  }
                </IconButton>
              </Box>
            }
          ></ImageListItemBar>
          {!isAvatar &&
            <input
              name={name}
              type="file"
              ref={inputFile}
              onChange={handleFileInputChange}
              style={{
                width: "1px",
                height: "1px",
                marginLeft:"50%",
                display: "block",
              }}
              required={required}
              {...(acceptFile && { accept: acceptFile })}
            />
          }
        </ImageListItem>
      </Stack >
      <PreviewModal
        src={src}
        open={openZoom}
        setOpen={setOpenZoom}
      />
      <AvatarCropper
        src={src || ""}
        open={openUpload}
        setOpen={setOpenUpload}
        setSrcPhoto={setSrc}
        formik={formik}
        name={name}
      />
      <ImageCrop
        setSrc={setSrc}
        srcRef={srcRef}
        open={openImageCrop}
        setOpen={setOpenImageCrop}
        formik={formik}
        name={name}
      />
      <CameraModal
        open={openCamera}
        setSrc={setSrc}
        setOpen={setOpenCamera}
        formik={formik}
        name={name}
      />
    </>
  );
};

FilePreview.propTypes = {
  lblBtnUpdate: PropTypes.any,
  src: PropTypes.any,
  setSrc: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
};
