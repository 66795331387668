import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  styled,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Toast, UIButton } from "components";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { TypesOfPermits } from "../CreateRequests/TypesOfPermits";
import { VacationRequest } from "../CreateRequests/VacationRequest";
import { JobDescription } from "modules/Organization/JobDescription/JobDescription";
import { useDispatch } from "react-redux";
import { setJobDescription } from "@redux/slices/jobDescription";
import { managerErrors } from "utils/functions";
import { singDocument } from "api";
import { useSelector } from "react-redux";
import { authUserSelect } from "@redux/slices/authUser";
import { JustifyForm } from "modules/ShiftAttendance/AttendanceReports/JustifyForm";
import { StaffRequest } from "modules/Recruitment/StaffRequests/StaffRequest";
import { setStaffRequest } from "@redux/slices/recruitment";
import { QuittingRequest } from "../CreateRequests/ContractEnd/QuittingRequest";
import { DismissalRequest } from "../CreateRequests/ContractEnd/DismissalRequest";

import { ConfirmDialog } from "components/ConfirmDialog";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export const SignModal = ({
  open,
  setOpen,
  typeDocument,
  request,
  signAuth,
  confirmSign,
}) => {
  const { t } = useTranslation(["request"]);
  const { user } = useSelector(authUserSelect);

  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitAction, setSubmitAction] = useState(null);

  const dispatch = useDispatch();

  if (typeDocument === "staff") {
    dispatch(setStaffRequest(request));
  }

  if (typeDocument === "jobPosition") {
    dispatch(setJobDescription(request));
  }
  const onSubmit = async (action, reason = null) => {
    try {
      setIsSubmitting(true);
      let data = {};
      let signAction = "sign";

      if (action !== "APPROVE") {
        data["reason"] = reason;
        data["type"] = action;
        signAction = "reject";
      }

      await singDocument(signAuth._id, user._id, data, signAction);
      Toast.fire({
        icon: "success",
        title: t("general:AcciónExitosa"),
      });
      setIsSubmitting(false);
      setOpen(false);
      confirmSign();
    } catch (error) {
      console.error(error);
      setIsSubmitting(false);
      managerErrors(error?.response?.data?.message);
    }
  };

  return (
    <>
      <BootstrapDialog
        aria-labelledby="customized-dialog-title"
        open={open}
        maxWidth="lg"
        fullWidth={true}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          {t("request:ActionsForRequest")}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => setOpen(false)}
          sx={(theme) => ({
            position: "absolute",
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
          })}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          {typeDocument === "permissions" && (
            <TypesOfPermits
              request={request}
              readOnly={true}
              setOpen={setOpen}
            />
          )}
          {typeDocument === "vacation" && (
            <VacationRequest
              request={request}
              readOnly={true}
              setOpen={setOpen}
            />
          )}
          {typeDocument === "jobPosition" && (
            <JobDescription
              readOnly={true}
              application="employee"
              setOpen={setOpen}
            />
          )}
          {typeDocument === "justifications" && (
            <JustifyForm
              justificationRequest={request}
              readOnly={true}
              setOpen={setOpen}
            />
          )}
          {typeDocument === "staff" && (
            <StaffRequest request={request} readOnly={true} setOpen={setOpen} />
          )}
          {typeDocument === "quitting" && (
            <QuittingRequest
              request={request}
              readOnly={true}
              setOpen={setOpen}
            />
          )}
          {typeDocument === "termination" && (
            <DismissalRequest
              request={request}
              readOnly={true}
              setOpen={setOpen}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Stack
            direction={{ xs: "column", sm: "row" }}
            spacing={{ xs: 1, sm: 1, md: 1 }}
          >
            <UIButton
              label={t("request:aprobar")}
              variant="contained"
              onClick={() => {
                onSubmit("APPROVE");
              }}
              loading={isSubmitting}
              fullWidth={false}
            />
            <UIButton
              label={t("request:solicitarCambio")}
              variant="contained"
              onClick={() => {
                setOpen(false);
                setSubmitAction("TEMPORAL");
                setOpenConfirmDialog(true);
              }}
              loading={isSubmitting}
              fullWidth={false}
            />
            <UIButton
              label={t("request:rechazar")}
              variant="contained"
              onClick={() => {
                setOpen(false);
                setSubmitAction("FINAL");
                setOpenConfirmDialog(true);
              }}
              loading={isSubmitting}
              fullWidth={false}
            />
          </Stack>
        </DialogActions>
      </BootstrapDialog>
      <ConfirmDialog
        open={openConfirmDialog}
        setOpen={setOpenConfirmDialog}
        onCancel={() => {
          setOpen(true);
        }}
        onConfirm={async (message) => {
          if (submitAction) {
            await onSubmit(submitAction, message);
          }
        }}
        requiredMessage={true}
      />
    </>
  );
};
