import { useEffect, useState } from "react";
import {
  Box,
  Checkbox,
  CircularProgress,
  DialogContent,
  FormControlLabel,
  FormGroup,
  Grid,
  Stack,
  TableCell,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  BasicTable,
  ConfirmDialog,
  UIBootstrapDialog,
  UIButton,
} from "components";
import { Form, Formik } from "formik";
import { FormVacation } from "./FormVacation";
import {
  getJobPositions,
  getHolidays,
  getCountries,
  requestFlowDismissals,
  requestFlowVacation,
} from "api";
import _ from "lodash";
import { useAccess } from "hooks";
import { FormDismissals } from "./FormDismissals";
import { AddButton } from "./ButtonAdd";
import * as Yup from "yup";

export const FlowsAutorizations = ({ employees = [] }) => {
  const { t } = useTranslation();

  const [finish, setFinish] = useState(false);
  const [dataFlows, setDataFlows] = useState([]);
  const [countries, setCountries] = useState([]);
  const [holidays, setHolidays] = useState([]);
  const [dataTable, setDataTable] = useState([]);
  const [flow, setFlow] = useState(null);
  const [open, setOpen] = useState(false);
  const [permissions, setPermissions] = useState({
    canAdd: false,
    canChange: false,
    canDelete: false,
  });
  const [typeFlow, setTypeFlow] = useState("");
  const [jobPositions, setJobPositions] = useState([]);
  const [validationSchema, setValidationSchema] = useState(null);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

  const { HasPermissions } = useAccess();
  let {
    canView: canViewVacations,
    canAdd: canAddVacations,
    canChange: canChangeVacations,
    canDelete: canDeleteVacations,
  } = HasPermissions("/cereza-organization/settings/vacations-flow");
  let {
    canView: canViewDismissals,
    canAdd: canAddDismissals,
    canChange: canChangeDismissals,
    canDelete: canDeleteDismissals,
  } = HasPermissions(
    "/cereza-organization/settings/resignations-dismissals-flow",
  );
  const [typesFlows, setTypesFlows] = useState({});

  const createDataTable = (data, countries, typesFlows) => {
    const filter = _.reduce(
      typesFlows,
      (result, value, key) => {
        if (value) {
          result.push(key);
        }
        return result;
      },
      [],
    );

    if (filter.length) {
      data = _.filter(data, (f) => filter.includes(f._type));
    }

    setDataTable(
      _.orderBy(
        _.map(
          data,
          ({ _id, title, _type, type: typeRequest, country: countryId }) => {
            const country = countries.find((c) => c.value === countryId);

            return {
              id: _id,
              title,
              _type: t(`settings:${_type}`),
              typeRequest: typeRequest ? t(`options:${typeRequest}`) : "",
              country: country.label,
            };
          },
        ),
        [(row) => row.title.toLowerCase()],
        ["asc"],
      ),
    );
  };

  const generateFlowData = (data, dataFlows, type) => {
    return _.union(
      dataFlows,
      data.map((o) => {
        return { ...o, _type: type };
      }),
    );
  };

  useEffect(() => {
    const getData = async () => {
      try {
        let typesFlowsInit = {};

        if (canViewVacations) {
          typesFlowsInit["vacations"] = false;
        }
        if (canViewDismissals) {
          typesFlowsInit["dismissals"] = false;
        }

        let dataFlows = [];
        const { data: dHolidays } = await getHolidays(null);
        const { data: dCountries } = await getCountries(false);
        const dFlowVacation = await requestFlowVacation({
          method: "GET",
          t,
        });
        const dFlowDismissals = await requestFlowDismissals({
          method: "GET",
          t,
        });
        const { data: dJobPositions } = await getJobPositions(null);

        dataFlows = generateFlowData(dFlowVacation, dataFlows, "vacations");
        dataFlows = generateFlowData(dFlowDismissals, dataFlows, "dismissals");

        const countriesValues = dCountries.map(({ _id, name }) => ({
          value: _id,
          label: name,
        }));

        setDataFlows(dataFlows);
        setCountries(countriesValues);
        setHolidays(
          dHolidays.map(({ _id, name }) => ({ value: _id, label: name })),
        );
        setJobPositions(
          dJobPositions.map(({ _id, title }) => ({ value: _id, label: title })),
        );

        setTypesFlows(typesFlowsInit);
        createDataTable(dataFlows, countriesValues, typesFlowsInit);
        setFinish(true);
      } catch (e) {
        console.log(e);
      }
    };
    if (employees.length) {
      getData();
    }
  }, [employees]);

  const flowModalOpen = (dFlow) => {
    setTypeFlow(dFlow._type);

    switch (dFlow._type) {
      case "vacations":
        setPermissions({
          canAdd: canAddVacations,
          canChange: canChangeVacations,
          canDelete: canDeleteVacations,
        });
        setValidationSchema(null);
        break;

      case "dismissals":
        setPermissions({
          canAdd: canAddDismissals,
          canChange: canChangeDismissals,
          canDelete: canDeleteDismissals,
        });
        setValidationSchema(
          Yup.object().shape({
            alerts: Yup.array().of(
              Yup.object().shape({
                subTitle: Yup.string().required(t("settings:isRequired")),
                employees: Yup.array().min(
                  1,
                  t("settings:SelectLeastOneOption"),
                ),
                jobPositions: Yup.array().min(
                  1,
                  t("settings:SelectLeastOneOption"),
                ),
                message: Yup.string().required(t("settings:isRequired")),
              }),
            ),
          }),
        );
        break;

      default:
        setPermissions({
          canAdd: false,
          canChange: false,
          canDelete: false,
        });
        setValidationSchema(null);
        break;
    }

    setFlow(dFlow);
    setOpen(true);
  };

  const handleClick = (row) => {
    const dFlow = dataFlows.find((v) => v._id === row.id);
    flowModalOpen(dFlow);
  };

  const onSubmit = async (_values) => {
    let { _type, ...values } = _values;
    const request = {
      method: values?._id ? "PUT" : "POST",
      data: values,
      messageSuccess: values?._id
        ? t("organization:FlowSettingSuccessfullyUpdated")
        : t("organization:FlowSettingSuccessfullyCreated"),
      t,
    };
    const data =
      typeFlow === "vacations"
        ? await requestFlowVacation(request)
        : await requestFlowDismissals(request);

    if (data) {
      updateData(values?._id ? "update" : "add", { _type, ...data });
    }
  };

  const deleteFlow = async (_item) => {
    let { _type, ...values } = _item;
    const request = {
      method: "DELETE",
      data: values,
      messageSuccess: t("organization:FlowSettingSuccessfullyDeleted"),
      t,
    };
    const data =
      typeFlow === "vacations"
        ? await requestFlowVacation(request)
        : await requestFlowDismissals(request);

    if (data) {
      updateData("delete", _item);
    }
  };

  const updateData = async (action, data) => {
    let newData = _.cloneDeep(dataFlows);

    switch (action) {
      case "add":
        newData.push(data);
        break;

      case "update":
        newData = _.unionBy([data], newData, "_id");
        break;

      case "delete":
        newData = newData.filter((o) => o._id != data._id);
        break;

      default:
        break;
    }

    setDataFlows(newData);
    createDataTable(newData, countries, typesFlows);
    setOpen(false);
  };

  return (
    <Box pl={2} pr={2} pb={2}>
      {finish ? (
        <>
          <Grid container spacing={2}>
            <Grid item xs={12} md={9}>
              <BasicTable rows={dataTable} handleClick={handleClick}>
                <TableCell>{t("organization:NameRule")}</TableCell>
                <TableCell>{t("settings:Type")}</TableCell>
                <TableCell>{t("settings:TypeRequest")}</TableCell>
                <TableCell>{t("organization:Country")}</TableCell>
              </BasicTable>
            </Grid>
            <Grid item xs={12} md={3}>
              <Stack direction="column" spacing={2}>
                <AddButton
                  flowModalOpen={flowModalOpen}
                  canAddVacations={canAddVacations}
                  canAddDismissals={canAddDismissals}
                />
                <FormGroup mt={2}>
                  {Object.keys(typesFlows).map((key) => {
                    const total = _.filter(
                      dataFlows,
                      (f) => f._type === key,
                    ).length;

                    return (
                      <FormControlLabel
                        key={key}
                        control={
                          <Checkbox
                            checked={typesFlows[key]}
                            onChange={(e) => {
                              let newTypesFlows = _.cloneDeep(typesFlows);
                              newTypesFlows[key] = e.target.checked;
                              setTypesFlows(newTypesFlows);
                              createDataTable(
                                dataFlows,
                                countries,
                                newTypesFlows,
                              );
                            }}
                          />
                        }
                        label={
                          <Stack
                            direction="row"
                            spacing={1}
                            sx={{
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <Typography>{t(`settings:${key}`)}</Typography>
                            <Typography color="primary">{total}</Typography>
                          </Stack>
                        }
                      />
                    );
                  })}
                </FormGroup>
              </Stack>
            </Grid>
          </Grid>
        </>
      ) : (
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={2}
        >
          <CircularProgress />
        </Stack>
      )}
      <UIBootstrapDialog
        title={t("organization:SettingAutorizationFlow")}
        open={open}
        setOpen={setOpen}
      >
        <DialogContent>
          <Formik
            initialValues={flow}
            onSubmit={onSubmit}
            enableReinitialize={true}
            validateOnChange={false}
            validationSchema={validationSchema}
          >
            {(formik) => (
              <Form autoComplete="false">
                {typeFlow === "vacations" && (
                  <FormVacation
                    formik={formik}
                    countries={countries}
                    holidays={holidays}
                    employees={employees}
                    canAdd={permissions.canAdd}
                    canChange={permissions.canChange}
                  />
                )}
                {typeFlow === "dismissals" && (
                  <FormDismissals
                    formik={formik}
                    countries={countries}
                    employees={employees}
                    jobPositions={jobPositions}
                    canAdd={permissions.canAdd}
                    canChange={permissions.canChange}
                  />
                )}
                <Stack
                  mt={2}
                  direction={{ xs: "column", sm: "row" }}
                  spacing={{ xs: 1, sm: 1, md: 1 }}
                >
                  {((permissions.canAdd && !formik.values?._id) ||
                    (permissions.canChange && formik.values._id)) && (
                    <UIButton
                      type="submit"
                      label={
                        formik.values?._id
                          ? t("general:Actualizar")
                          : t("general:Guardar")
                      }
                      loading={formik.isSubmitting}
                      fullWidth={false}
                    />
                  )}
                  {permissions.canDelete && formik.values._id && (
                    <UIButton
                      label={t("general:Eliminar")}
                      onClick={() => {
                        setOpen(false);
                        setOpenConfirmDialog(true);
                      }}
                      disabled={formik.isSubmitting}
                      fullWidth={false}
                    />
                  )}
                </Stack>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </UIBootstrapDialog>
      <ConfirmDialog
        open={openConfirmDialog}
        setOpen={setOpenConfirmDialog}
        onConfirm={() => {
          deleteFlow(flow);
        }}
        onCancel={() => {
          setOpen(true);
        }}
      />
    </Box>
  );
};
