import FormatColorResetIcon from "@mui/icons-material/FormatColorReset";
import { Box, Grid, Typography, TextField, IconButton } from "@mui/material";
import { useTranslation } from "react-i18next";

export const UIColorPicker = ({
  label = "",
  value,
  onChange,
  isrequired = true,
  disabled = false,
}) => {
  const { t } = useTranslation(["general"]);
  return (
    <Grid container spacing={2}>
      <Grid item={true} xs={12} md={6}>
        <TextField
          fullWidth
          type="color"
          label={value === "" ? "" : label}
          value={value}
          onChange={onChange}
          required={isrequired}
          disabled={disabled}
        />
      </Grid>
      <Grid item={true} xs={12} md={6}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: (isrequired || !value ) ? "center" : "space-between",
            gap: "1rem",
            width: "100%",
            border: 1,
            borderRadius: 1,
            borderColor: "rgba(0, 0, 0, 0.23)",
            padding: isrequired ? 2 : "14.8px",
            "&:hover": {
              borderColor: "rgba(0, 0, 0, 0.87)",
            },
          }}
        >
          <Typography variant="subtitle2">
            <b>HEX:</b>
            {(value) ? value : " " + t("general:No")}
          </Typography>
          {(!isrequired && value) && (
            <Box sx={{ maxWidth: "min-content" }}>
              <IconButton
                sx={{ padding: 0 }}
                onClick={() => onChange({ target: { value: "" } })}
              >
                <FormatColorResetIcon />
              </IconButton>
            </Box>
          )}
        </Box>
      </Grid>
    </Grid>
  );
};
