import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { BasicTable, UIButton, UIPaper } from "components";
import { useTranslation } from "react-i18next";
import {
  Box,
  CircularProgress,
  Grid,
  Stack,
  TableCell,
  Typography,
} from "@mui/material";
import { generalSelect } from "@redux/slices/general";
import { ModalSettings } from "./ModalSettings";
import { requestTimeCheck } from "api";
import { useLocations } from "hooks";
import { useAccess } from "hooks";

export const ShiftAttendanceSettings = () => {
  const { t } = useTranslation(["shiftAttendance"]);
  const { HasPermissions } = useAccess();

  const { refreshLocationsSeleectors } = useLocations();
  refreshLocationsSeleectors();

  const { locations } = useSelector(generalSelect);

  const [rows, setRows] = useState([]);
  const [open, setOpen] = useState(false);
  const [responseData, setResponseData] = useState([]);
  const [newData, setNewData] = useState(false);
  const [values, setValues] = useState(undefined);
  const [finish, setFinish] = useState(false);

  const { canAdd, canChange, canDelete } = HasPermissions(
    "/cereza-organization/shift-attendance/shift-attendance-settings",
  );

  useEffect(() => {
    const fetchTimeCheck = async () => {
      try {
        const data = await requestTimeCheck({ method: "GET", t });
        const locationsIndex = locations.reduce((acc, row) => {
          acc[row.value] = row;
          return acc;
        }, {});

        const rows = data.map((row) => ({
          id: row._id,
          name: row.name,
          type: t(`options:${row.type}`),
          locations: locationsIndex[row.location]?.label ?? "",
          isActive:
            row.isActive === true
              ? t("shiftAttendance:Si")
              : t("shiftAttendance:No"),
        }));
        setRows(rows);

        setResponseData(
          data.reduce((acc, row) => {
            acc[row._id] = row;
            return acc;
          }, {}),
        );
        setFinish(true);
      } catch (err) {
        console.error("Error: ", err);
        setFinish(true);
      }
    };

    fetchTimeCheck();
  }, [newData]);

  const handleClick = (row) => {
    setValues(responseData[row?.id]);
    setOpen(true);
  };

  return finish ? (
    <UIPaper>
      <Box p={2}>
        <Grid container spacing={2} mb={2}>
          <Grid item={true} xs={12} md={9}>
            <Typography variant="h5" mb={2}>
              {t("shiftAttendance:RelojesChecadores")}
            </Typography>
          </Grid>
          {canAdd && (
            <Grid item={true} xs={12} md={3}>
              <UIButton
                onClick={() => {
                  setValues(undefined);
                  setOpen(true);
                }}
              >
                {t("shiftAttendance:Crear")}
              </UIButton>
            </Grid>
          )}
        </Grid>
        <BasicTable rows={rows} handleClick={handleClick}>
          <TableCell>{t("shiftAttendance:RelojChecador")}</TableCell>
          <TableCell>{t("shiftAttendance:Tipo")}</TableCell>
          <TableCell>{t("shiftAttendance:Ubicaciones")}</TableCell>
          <TableCell>{t("shiftAttendance:Activo")}</TableCell>
        </BasicTable>
      </Box>
      <ModalSettings
        open={open}
        setOpen={setOpen}
        setNewData={setNewData}
        values={values}
        locations={locations}
        canAdd={canAdd}
        canChange={canChange}
        canDelete={canDelete}
      />
    </UIPaper>
  ) : (
    <Stack
      direction="row"
      justifyContent="center"
      alignItems="center"
      spacing={2}
    >
      <CircularProgress />
    </Stack>
  );
};
