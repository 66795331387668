// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.97.1/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.97.1/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gridWidgets {
  display: grid;
  gap: 1rem;
  grid-template-areas:
    "colegas  calendar calendar holiday"
    "clima   vacation vacation darkMode";
}

@media screen and (max-width: 768px) {
  .gridWidgets {
    grid-template-areas:
      "colegas"
      "calendar"
      "holiday"
      "clima"
      "darkMode"
      "vacation";
  }
}

.colegas {
  grid-area: colegas;
}

.calendar {
  grid-area: calendar;
}

.holiday {
  grid-area: holiday;
}

.darkMode {
  grid-area: darkMode;
}

.clima {
  grid-area: clima;
}

.vacation {
  grid-area: vacation;
}

.calendar-todo-list {
  list-style: none;
  padding: 0;
  text-align: left;
}

.calendar-todo-list li {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
`, "",{"version":3,"sources":["webpack://./src/app/modules/Home/styles.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,SAAS;EACT;;wCAEsC;AACxC;;AAEA;EACE;IACE;;;;;;gBAMY;EACd;AACF;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;EAChB,UAAU;EACV,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;EAChB,uBAAuB;EACvB,mBAAmB;AACrB","sourcesContent":[".gridWidgets {\n  display: grid;\n  gap: 1rem;\n  grid-template-areas:\n    \"colegas  calendar calendar holiday\"\n    \"clima   vacation vacation darkMode\";\n}\n\n@media screen and (max-width: 768px) {\n  .gridWidgets {\n    grid-template-areas:\n      \"colegas\"\n      \"calendar\"\n      \"holiday\"\n      \"clima\"\n      \"darkMode\"\n      \"vacation\";\n  }\n}\n\n.colegas {\n  grid-area: colegas;\n}\n\n.calendar {\n  grid-area: calendar;\n}\n\n.holiday {\n  grid-area: holiday;\n}\n\n.darkMode {\n  grid-area: darkMode;\n}\n\n.clima {\n  grid-area: clima;\n}\n\n.vacation {\n  grid-area: vacation;\n}\n\n.calendar-todo-list {\n  list-style: none;\n  padding: 0;\n  text-align: left;\n}\n\n.calendar-todo-list li {\n  overflow: hidden;\n  text-overflow: ellipsis;\n  white-space: nowrap;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
