import { useTranslation } from "react-i18next";
import { UIBootstrapDialog, UIButton, UITextFast } from "components";
import { Box, Stack, DialogContent } from "@mui/material";
import { managerErrors } from "utils";
import { Formik, Form } from "formik";
import React, { useRef, useState } from "react";
import { parameterizationSelect } from "@redux/slices/parameterization";
import { useSelector } from "react-redux";
import Alert from "@mui/material/Alert";

import * as Yup from "yup";

export const ConfirmDialog = ({
  open,
  setOpen,
  onConfirm = null,
  onCancel = null,
  icon = null,
  title = null,
  text = null,
  requiredMessage = false,
  optionalMessage = false,
  confirmButtonText = null,
  cancelButtonText = null,
}) => {
  const { mode } = useSelector(parameterizationSelect);
  requiredMessage = optionalMessage ? false : requiredMessage;
  const needMessage = requiredMessage || optionalMessage;
  const { t } = useTranslation();

  const [iValues] = useState({
    message: "",
  });
  const formikRef = useRef(null);

  const validationSchema = Yup.object({
    message: requiredMessage
      ? Yup.string().required(t("settings:isRequired"))
      : Yup.string(),
  });

  const Icon = icon
    ? React.cloneElement(icon, { style: { fontSize: 60 } })
    : icon;

  const AlertDefault = (
    <Alert
      {...(mode === "dark" && { variant: "outlined" })}
      sx={{ mt: 2 }}
      severity="warning"
    >
      {text || t("request:confirmAlert")}
    </Alert>
  );

  const handleSubmit = async (values, { resetForm }) => {
    try {
      let message = values.message;
      handleConfirm(message);
      resetForm();
    } catch (error) {
      managerErrors(err?.response?.data?.message);
      console.error("Error submitting form:", err);
    }
  };

  const handleConfirm = (arg) => {
    if (onConfirm) onConfirm(arg);
    setOpen(false);
  };

  const handleCancel = () => {
    if (onCancel) onCancel();
    setOpen(false);
  };

  const ConfirmButtonText = confirmButtonText || t("request:enviarMessage");

  const CancelButtonText = !cancelButtonText
    ? t("general:Cancel")
    : cancelButtonText;

  return (
    <>
      <UIBootstrapDialog
        title={title || t("general:Are")}
        open={open}
        setOpen={setOpen}
        onClose={handleCancel}
      >
        <DialogContent>
          {Icon && <Box alignSelf="center">{Icon}</Box>}
          {needMessage && (
            <Formik
              innerRef={formikRef}
              initialValues={iValues}
              onSubmit={handleSubmit}
              enableReinitialize={true}
              validationSchema={validationSchema}
            >
              <Form
                id="d"
                autoComplete="off"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                }}
              >
                <UITextFast
                  label={t("request:Typeyourmessagehere")}
                  name={"message"}
                  required={requiredMessage}
                />
              </Form>
            </Formik>
          )}
          {AlertDefault}
          <Box display="flex" justifyContent="flex-start" width="100%" mt={3}>
            <UIButton
              onClick={handleCancel}
              style={{
                flexGrow: 0,
                maxWidth: "min-content",
                marginRight: "8px",
                whiteSpace: "nowrap",
              }}
              disabled={
                formikRef.current ? formikRef.current?.isSubmitting : false
              }
            >
              {CancelButtonText}
            </UIButton>
            <UIButton
              onClick={
                needMessage
                  ? () => formikRef.current.submitForm()
                  : handleConfirm
              }
              variant="contained"
              style={{
                flexGrow: 0,
                maxWidth: "min-content",
                whiteSpace: "nowrap",
              }}
              disabled={
                formikRef.current ? formikRef.current?.isSubmitting : false
              }
            >
              {ConfirmButtonText}
            </UIButton>
          </Box>
        </DialogContent>
      </UIBootstrapDialog>
    </>
  );
};
