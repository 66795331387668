import { useEffect, useRef, useState } from 'react';
import { getDepartments, getJobPositions, selectingEmployeeList } from 'api';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import _ from 'lodash';
import { OrganizationalTree } from './OrganizationalTree';
import { tenantSettingsSelect } from '@redux/slices/tenant';
import { parameterizationSelect } from '@redux/slices/parameterization';
import { useSelector } from 'react-redux';
import {
  Avatar,
  Box,
  Button,
  Chip,
  CircularProgress,
  Paper,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import {
  PersonRemove as PersonRemoveIcon,
  Add,
  Remove,
  RestartAlt,
  Download,
} from '@mui/icons-material';
import { setEmployeeSelected } from '@redux/slices/profile';
import { getEmployee } from 'api';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useAccess } from 'hooks';
import { pathEmployee } from 'helpers';
import jsPDF from 'jspdf';
import { toPng } from 'html-to-image';
import { EmployeeButton } from "./EmployeeButton";

function generateNodesTree(employees, setOpen) {
  return employees.map((employee) => {
    let node = {
      title: employee.fullName,
      subheader: employee?.position,
      avatar: employee.thumbnail,
      cardStyle: null,
    };

    const department = employee.department;

    if (department && !department.isInternal) {
      node.cardStyle = { border: '2px dashed #cacfd2' };
    }

    if (department) {
      node.subheader = (
        <>
          <Typography variant='subtitle2' component={'div'}>
            {employee?.position}
          </Typography>
          <Typography variant='caption' color='primary'>
            {department.name}
          </Typography>
        </>
      );
    }

    if (employee.supervises && employee.supervises.length) {
      node.childs = generateNodesTree(employee.supervises, setOpen);
    }

    return node;
  });
}

export const Chart = ({ user = null, application = 'organization' }) => {
  const { t } = useTranslation(['organization']['general']);
  const { tenant } = useSelector(tenantSettingsSelect);
  const { logo } = useSelector(parameterizationSelect);
  const [tree, setTree] = useState(null);
  const mapRef = useRef(null);
  const wrapperRef = useRef(null);
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { HasPermissions } = useAccess();
  const { canAccess: canAccessEmployee } = HasPermissions(
    pathEmployee(application, '/profile/collaborator/personal-information'),
    application,
  );

  const actionEmployeeClick = async ({ employee }) => {
    try {
      const { data } = await getEmployee('id', employee._id);
      dispatch(setEmployeeSelected(data));
      navigate(
        '/cereza-organization/employees/profile/collaborator/personal-information',
      );
      if (application === 'employee') {
        window.location.reload();
      }
    } catch (err) {
      console.log('Error gettin employee: ', err);
    }
  };

  const createTree = ({
    departments,
    employees,
    jobDescriptions,
    supervisor,
    showVacancies,
  }) => {
    let nodes = [];
    let total = employees.length;

    for (var i = 0; i < total; i++) {
      let employee = employees[i];

      if (employee.supervisor === supervisor) {
        employee['supervises'] = _.orderBy(
          createTree({
            departments,
            employees,
            jobDescriptions,
            supervisor: employee.fullName,
            showVacancies,
          }),
          ['positionId'],
          ['desc'],
        );

        if (canAccessEmployee && employee) {
          employee.fullName = (
            <EmployeeButton
               employeeName={employee.fullName}
               onShortClick={() => actionEmployeeClick({employee})}
             />
          );
        }
        employee.thumbnail = employee.thumbnail ? (
          <Avatar src={employee.thumbnail}></Avatar>
        ) : (
          <Avatar></Avatar>
        );
        employee.department = jobDescriptions[employee.positionId]
          ? jobDescriptions[employee.positionId].department
          : null;

        if (!employee.isActive) {
          employee.position = (
            <Tooltip title={t('organization:EmployeeInactive')}>
              <Chip
                color='error'
                label={employee.position}
                icon={<PersonRemoveIcon />}
                size='small'
              />
            </Tooltip>
          );
        }
        const jobDescription = jobDescriptions[employee.positionId];
        const supervises = jobDescription
          ? _.filter(jobDescriptions, (o) => o.reportAt === jobDescription._id)
          : null;

        if (showVacancies && supervises && supervises.length) {
          let quantity = _.filter(
            supervises,
            (s) => s.occupyIn._id === employee._id && s.quantity > 0,
          );
          quantity = _.map(quantity, (s) => {
            return {
              fullName: (
                <Typography variant='button' color='secondary' component='div'>
                  {t('employee:vacancies')}
                </Typography>
              ),
              position: s.title,
              thumbnail: (
                <Avatar sx={{ bgcolor: theme.palette.primary.main }}>
                  {s.quantity}
                </Avatar>
              ),
              cardStyle: null,
            };
          });

          if (quantity.length) {
            employee.supervises = _.concat(employee.supervises, quantity);
          }
        }

        if (employee.isActive || employee.supervises.length) {
          nodes.push(employee);
        }
      }
    }

    return nodes;
  };

  useEffect(() => {
    const getDataBase = async () => {
      let { data: departments } = await getDepartments(null);
      let { data: employees } = await selectingEmployeeList();
      let { data: jobDescriptions } = await getJobPositions(null);

      jobDescriptions = jobDescriptions.reduce((acc, j) => {
        let employesPosition = _.filter(
          employees,
          (o) => o.positionId === j._id && o.isActive,
        );

        j.department = _.find(departments, (o) => o._id === j.department);
        let reportAt = _.filter(employees, (o) => o.positionId === j.reportAt);
        j.occupiedPositions = employesPosition.length;
        j.available = j.occupiedPositions < j.quantity;
        j.quantity = j.quantity - j.occupiedPositions;
        j.occupyIn = reportAt.length ? reportAt[0] : [];

        acc[j._id] = j;

        return acc;
      }, {});

      if (user) {
        let employee = _.find(
          employees,
          (o) => o._id === user.employeeSelected._id,
        );
        const supervises = _.filter(
          employees,
          (o) => o.supervisor === employee.fullName,
        );

        if (supervises && supervises.length) {
          employee.supervisor = '';
          employees = [employee, ...supervises];
        } else {
          let supervisor = _.find(
            employees,
            (o) => o.fullName === employee.supervisor,
          );

          if (supervisor) {
            const supervises = _.filter(
              employees,
              (o) => o.supervisor === supervisor.fullName,
            );

            supervisor.supervisor = '';
            employees = [supervisor, ...supervises];
          } else {
            const supervises = _.filter(employees, (o) => o.supervisor === '');
            employees = [...supervises];
          }
        }
      }

      let t = {
        title: tenant.legalName,
        subheader: '',
        avatar: <Avatar src={logo} variant='square'></Avatar>,
        cardStyle: null,
        childs: generateNodesTree(
          createTree({
            departments,
            employees,
            jobDescriptions,
            supervisor: '',
            showVacancies: user ? false : true,
          }),
        ),
      };

      setTree(t);
    };

    getDataBase();
  }, []);

  const exportPdf = () => {
    const document = new jsPDF('l', 'mm', 'a4');
    const chart = mapRef.current;
    if (chart) {
      toJpeg(chart)
        .then((data) => {
          document.addImage(data, 'JPEG', 5, 5, 280, 180);
          document.save('OrganizationalChart.pdf');
        })
        .catch((error) => {
          console.log('Error al general el PDF', error);
        });
    }
  };

  const exportPng = () => {
    const chartPng = mapRef.current;
    if (chartPng) {
      toPng(chartPng, { cacheBust: true })
        .then((dataUrl) => {
          const link = document.createElement('a');
          link.download = 'OrganizationalChart.png';
          link.href = dataUrl;
          link.click();
        })
        .catch((err) => {
          console.error('Error al generar la imagen', err);
        });
    }
  };

  return (
    <>
      {tree ? (
        <Paper
          sx={{
            height: 'auto',
            maxHeight: 'calc(100vh - 230px)',
            overflow: 'hidden',
          }}
        >
          <Box
            p={2}
            sx={{
              overflow: 'auto',
              maxWidth: '100vw',
              maxHeight: '100vh',
              '::-webkit-scrollbar': { display: 'none' },
              msOverflowStyle: 'none',
              scrollbarWidth: 'none',
            }}
          >
            <Typography variant='h5'>
              {user ? t('sidenav:WorkTeam') : t('sidenav:Organigrama')}
            </Typography>
          </Box>
          <TransformWrapper
            ref={wrapperRef}
            centerOnInit
            limitToBounds={true}
            initialScale={0.9}
            key={'collapsed'}
            minScale={0.5}
            maxScale={4}
            wheel={{
              wheelDisabled: false,
              touchPadDisabled: false,
              step: 0.1,
            }}
            panning={{
              disabled: false,
              velocityDisabled: false,
            }}
            disablePadding
          >
            {({ zoomIn, zoomOut, resetTransform }) => (
              <>
                <Box
                  sx={{
                    position: 'absolute',
                    pl: 7,
                    zIndex: 1000,
                    display: 'flex',
                    maxWidth: '10px',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <Button variant='contained' onClick={() => zoomIn(0.05)}>
                    <Add />
                  </Button>
                  <Button
                    variant='contained'
                    onClick={() => zoomOut(0.05)}
                    sx={{ mt: 1 }}
                  >
                    <Remove />
                  </Button>
                  <Button
                    variant='contained'
                    onClick={() => resetTransform()}
                    sx={{ mt: 1 }}
                  >
                    <RestartAlt />
                  </Button>
                </Box>
                <Box
                  sx={{
                    position: 'absolute',
                    right: 60,
                    zIndex: 1000,
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  <Button
                    variant='contained'
                    onClick={exportPdf}
                    sx={{ mt: 1, display: 'none' }}
                  >
                    PDF
                  </Button>
                  <Button
                    variant='contained'
                    onClick={exportPng}
                    sx={{ mt: 1 }}
                  >
                    <Download />
                  </Button>
                </Box>
                <TransformComponent
                  wrapperStyle={{ width: '100%', height: 'calc(90vh - 230px)' }}
                >
                  <Box
                    ref={mapRef}
                    sx={{
                      cursor: 'grab',
                      display: 'block',
                    }}
                  >
                    <OrganizationalTree o={tree} wrapperRef={wrapperRef} />
                  </Box>
                </TransformComponent>
              </>
            )}
          </TransformWrapper>
        </Paper>
      ) : (
        <Stack
          justifyContent='center'
          alignItems='center'
          spacing={2}
          style={{ height: '70vh' }}
        >
          <CircularProgress />
        </Stack>
      )}
    </>
  );
};
