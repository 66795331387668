import { Card, CardHeader, CardContent, Typography, IconButton } from "@mui/material";
import {
  ExpandLess as ExpandLessIcon,
  ExpandMore as ExpandMoreIcon,
} from "@mui/icons-material";
import { useEffect, useState } from "react";
import { Tree, TreeNode } from "react-organizational-chart";

export const ChildTree = ({ o, onCollapse, collapsed }) => {
  let style = {
    display: "inline-block",
    minWidth: "200px",
  };

  if (o?.cardStyle && o.cardStyle) {
    style = { ...style, ...o.cardStyle };
  }
  return (
    <Card
      sx={style}
    >
      <CardHeader
        avatar={o.avatar}
        title={o.title}
        sx={{
          textAlign: "center",
          flexDirection: 'column',
          paddingBottom: 0
        }}
      />
      <CardContent
        sx={{
          paddingTop: 0,
          paddingBottom: 10
        }}
      >
        <Typography variant="subtitle2">
          {o.subheader}
        </Typography>
        {onCollapse && o.childs?.length && (
          <IconButton onClick={onCollapse} style={{ alignSelf: 'center', paddingBottom: '0px' }}>
          {!collapsed ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </IconButton>
        )}
      </CardContent>
    </Card>
  );
};

export const OrganizationalTree = ({ o, wrapperRef, parent }) => {
  const [collapsed, setCollapsed] = useState(o.collapsed);
  const [reset, setReset] = useState(false);

  const handleCollapse = () => {
    setCollapsed((prev) => !prev);
    setReset(true);
  };

  useEffect(() => {
    o.collapsed = collapsed;
    if(wrapperRef?.current?.resetTransform)
    {
      wrapperRef?.current?.resetTransform();
    }
  }, [collapsed, o]); // Asegúrate de agregar 'o' como dependencia


  const T = parent ? TreeNode : (props) => (
    <Tree
      {...props}
      lineWidth={"2px"}
      lineColor={"#cacfd2"}
      lineStyle={"solid"}
    >
      {props.children}
    </Tree>
  );

  return collapsed
    ? (
      <T
        label={
          <ChildTree o={o} onCollapse={handleCollapse} collapsed={collapsed} />
        }
      />
    )
    : (
      <T
        label={
          <ChildTree o={o} onCollapse={handleCollapse} collapsed={collapsed} />
        }
      >
        {o.childs?.map((c, index) => (
          <OrganizationalTree key={index} o={c} parent={o} />
        ))}
      </T>
    );
};
