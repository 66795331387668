import {
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { ErrorMessage, Field, useField } from "formik";
import { useState } from "react";
import {
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
} from "@mui/icons-material";

export const UIPasswordFast = (props) => {
  const { name, helperText, ...rest } = props;
  const [field] = useField(name);
  const [showPassword, setShowPassword] = useState(false);

  return (
    <Field {...field} {...rest}>
      {({ field }) => (
        <>
          <TextField
            sx={{
              width: "100%",
            }}
            type={showPassword ? "text" : "password"}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => {
                      setShowPassword(!showPassword);
                    }}
                  >
                    {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            {...field}
            {...rest}
          />
          <ErrorMessage
            name={field.name}
            render={(msg) => (
              <Typography color="error" variant="caption">
                {msg}
              </Typography>
            )}
          />
        </>
      )}
    </Field>
  );
};
