import { useTranslation } from "react-i18next";
import { CreationForm } from "./CreationForm";
import { useState } from "react";
import { UIBootstrapDialog } from "components";
import { DialogContent } from "@mui/material";
import { PasswordForm } from "./PasswordForm";

export const ModalSettings = ({
  open,
  setOpen,
  setNewData,
  values,
  locations,
  canAdd = false,
  canChange = false,
  canDelete = false,
}) => {
  const { t } = useTranslation(["shiftAttendance"]["general"]);
  const [password, setPassword] = useState(false);

  return (
    <UIBootstrapDialog
      title={t("shiftAttendance:RelojChecador")}
      open={open}
      setOpen={setOpen}
      onClose={() => {
        setOpen(false);
        setPassword(false);
      }}
    >
      <DialogContent>
        {password ? (
          <PasswordForm
            setPassword={setPassword}
            setOpen={setOpen}
            timeClock={values}
          />
        ) : (
          <CreationForm
            values={values}
            setPassword={setPassword}
            setOpen={setOpen}
            setNewData={setNewData}
            locations={locations}
            canAdd={canAdd}
            canChange={canChange}
            canDelete={canDelete}
          />
        )}
      </DialogContent>
    </UIBootstrapDialog>
  );
};
