import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { authUserSelect } from "@redux/slices/authUser";
import { useEffect, useState, useRef } from "react";
import { getEmployee, getFiles } from "api";
import { Form, Formik } from "formik";
import { UIButton, UIDatePickerFast, UIJoditEditor, Toast } from "components";
import { CircularProgress, Grid, Stack, Typography } from "@mui/material";
import { deleteContractEnd, postContractEnd, putContractEnd } from "api";
import { tenantSettingsSelect } from "@redux/slices/tenant";
import { setRequestUpdated } from "@redux/slices/requests";
import { MultipleFiles } from "./MultipleFiles";
import * as Yup from "yup";

export const QuittingRequest = ({
  onConfirmRef = null,
  setOpen = null,
  request = null,
  readOnly = false,
  saveInRedux = true,
}) => {
  const { t } = useTranslation();
  const { user } = useSelector(authUserSelect);
  const { tenant } = useSelector(tenantSettingsSelect);
  const [applicant, setApplicant] = useState({});
  const dispatch = useDispatch();

  const values = request
    ? request
    : {
        type: "QUITTING",
        reason: "OTHER",
        otherReason: "",
        documents: [],
        dateOfDeparture: "",
      };
  readOnly = readOnly ? readOnly : request?.status === "PENDING";

  const [finish, setFinish] = useState(false);
  const [files, setFiles] = useState([]);

  useEffect(() => {
    const getData = async () => {
      try {
        const user_id = request ? request.applicant : user._id;
        const { data: employeeInfo } = await getEmployee("id", user_id);
        const filesData = [];

        if (request) {
          for (const f of request.documents) {
            const { data: d } = await getFiles(tenant._id, f);
            filesData.push(d.content);
          }
        }

        setFiles(filesData);
        setApplicant(employeeInfo);
        setFinish(true);
      } catch (e) {
        console.log(e);
      }
    };

    getData();
  }, []);

  const onSubmit = async (values) => {
    let message = "";

    try {
      if (values._id) {
        const { data } = await putContractEnd(values);
        message = t("request:ResignationSuccessfullyUpdate");
        if (saveInRedux) {
          dispatch(
            setRequestUpdated({
              type: "quitting",
              action: "update",
              data: data,
            }),
          );
        }
      } else {
        const { data } = await postContractEnd(values);
        message = t("request:ResignationSuccessfullyCreated");
        if (saveInRedux) {
          dispatch(
            setRequestUpdated({
              type: "quitting",
              action: "add",
              data: data,
            }),
          );
        }
      }

      setOpen(false);
      Toast.fire({ icon: "success", title: message });
    } catch (err) {
      console.log(err);
      Toast.fire({
        icon: "error",
        title: err?.response?.data?.message
          ? err?.response?.data?.message
          : t("request:ResignationError"),
      });
    }
  };

  const deleteItem = async (item) => {
    try {
      await deleteContractEnd(item._id);
      dispatch(
        setRequestUpdated({
          type: "quitting",
          action: "delete",
          data: item,
        }),
      );
      setOpen(false);
      Toast.fire({
        icon: "success",
        title: t("request:ResignationSuccessfullyDelete"),
      });
    } catch (err) {
      Toast.fire({
        icon: "error",
        title: err?.response?.data?.message
          ? err?.response?.data?.message
          : t("request:ResignationErrorDelete"),
      });
    }
  };

  return finish ? (
    <>
      <Formik
        initialValues={values}
        onSubmit={onSubmit}
        validationSchema={Yup.object().shape({
          otherReason: Yup.string().required(t("settings:isRequired")),
        })}
      >
        {(formik) => (
          <Form autoComplete="off">
            <Grid container={true} spacing={2}>
              <Grid item={true} xs={12} md={5}>
                <Stack direction="column" spacing={1}>
                  <Typography variant="caption" color="primary">
                    {t("request:Employee")}
                  </Typography>
                  <Typography>
                    {applicant.names} {applicant.surnames}
                  </Typography>
                </Stack>
              </Grid>
              <Grid item={true} xs={12} md={3}>
                <UIDatePickerFast
                  name={"dateOfDeparture"}
                  label={t("request:dateOfDeparture")}
                  disabled={formik.isSubmitting || readOnly}
                />
              </Grid>
              <Grid item={true} xs={12} md={12}>
                <UIJoditEditor
                  name={"otherReason"}
                  label={t("request:ReasonOfResignation")}
                  disabled={formik.isSubmitting || readOnly}
                />
              </Grid>
              <Grid item={true} xs={12} md={12}>
                <MultipleFiles
                  formik={formik}
                  name="documents"
                  disabled={formik.isSubmitting || readOnly}
                  files={files}
                  setFiles={setFiles}
                />
              </Grid>
            </Grid>
            {!readOnly && (
              <Stack
                mt={2}
                direction={{ xs: "column", sm: "row" }}
                spacing={{ xs: 1, sm: 1, md: 1 }}
              >
                <UIButton
                  type="submit"
                  label={
                    formik?.values?._id
                      ? t("general:Actualizar")
                      : ["PENDING", "REVIEW_REQUIRED"].includes(
                            formik.values?.status,
                          )
                        ? t("general:Request")
                        : t("general:Guardar")
                  }
                  loading={formik.isSubmitting}
                  disabled={formik.isSubmitting}
                  fullWidth={false}
                />
                {formik.values?.status === "REVIEW_REQUIRED" && (
                  <UIButton
                    label={t("general:Eliminar")}
                    onClick={() => {
                      onConfirmRef.current = async () => {
                        formik.setSubmitting(true);
                        await deleteItem(formik.values);
                      }
                      setOpen(false);
                    }}
                    disabled={formik.isSubmitting}
                    fullWidth={false}
                  />
                )}
              </Stack>
            )}
          </Form>
        )}
      </Formik>
    </>
  ) : (
    <Stack
      direction="row"
      justifyContent="center"
      alignItems="center"
      spacing={2}
    >
      <CircularProgress />
    </Stack>
  );
};
