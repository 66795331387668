import { Grid, Box, Stack } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import { useTranslation } from "react-i18next";
import { Toast, UIButton, UIPasswordFast } from "components";
import { managerErrors } from "utils/functions";
import { changePassword } from "api";

export const PasswordForm = ({ setPassword, setOpen, timeClock }) => {
  const { t } = useTranslation(["shiftAttendance"]["general"]);

  const passwordValidationSchema = Yup.object().shape({
    password: Yup.string()
      .min(12, t("general:validation1"))
      .max(20, t("general:validation2"))
      .matches(/[a-zA-Z]/, t("general:validation3"))
      .matches(/\d/, t("general:validation4"))
      .matches(/[$@$!%*?&#]/, t("general:validation5"))
      .notOneOf(["123", "abc"], t("general:validation6"))
      .matches(/[a-z]/, t("general:validation7"))
      .matches(/[A-Z]/, t("general:8"))
      .matches(/\S/, t("general:9"))
      .required(t("general:validation10")),

    password2: Yup.string()
      .oneOf([Yup.ref("password"), null], t("general:validation11"))
      .required(t("general:validation12")),
  });

  const handleSubmit = async (values) => {
    try {
      await changePassword(timeClock._id, values);
      setOpen(false);
      setPassword(false);
      Toast.fire({
        icon: "success",
        title: t("general:AcciónExitosa"),
      });
    } catch (err) {
      managerErrors(err?.response?.data?.message);
      console.error("Error time check:", err);
    }
  };

  return (
    <>
      <Formik
        initialValues={{ password: "", password2: "" }}
        validationSchema={passwordValidationSchema}
        onSubmit={handleSubmit}
      >
        {(formik) => (
          <Form id="timeCheck" autoComplete="off">
            <Box sx={{ display: "grid", placeItems: "center" }}>
              <Grid
                container
                spacing={2}
                sx={{
                  width: "300px",
                  "@media (min-width: 600px)": {
                    width: "400px",
                  },
                }}
              >
                <Grid item={true} xs={12}>
                  <UIPasswordFast
                    label={t("shiftAttendance:NewPassword")}
                    name="password"
                    disabled={formik.isSubmitting}
                  />
                </Grid>
                <Grid item={true} xs={12}>
                  <UIPasswordFast
                    formik={formik}
                    label={t("shiftAttendance:ConfirmPassword")}
                    name={"password2"}
                    disabled={formik.isSubmitting}
                  />
                </Grid>
              </Grid>
            </Box>
            <Stack
              mt={2}
              direction={{ xs: "column", sm: "row" }}
              spacing={{ xs: 1, sm: 1, md: 1 }}
            >
              <UIButton
                onClick={() => {
                  setPassword(false);
                }}
                fullWidth={false}
                disabled={formik.isSubmitting}
              >
                <ArrowBackIcon />
              </UIButton>
              <UIButton
                type="submit"
                label={t("shiftAttendance:changePassword")}
                loading={formik.isSubmitting}
                fullWidth={false}
              />
            </Stack>
          </Form>
        )}
      </Formik>
    </>
  );
};
