import { Grid, Stack, Tooltip, Typography } from "@mui/material";
import { Form, Formik } from "formik";
import { TIME_CHECK_TYPES } from "utils/constants";
import {
  UIButton,
  UIPasswordFast,
  UIJoditEditor,
  UISelectFast,
  UISwitchFast,
  UITextFast,
} from "components";
import { requestTimeCheck } from "api";
import { useTranslation } from "react-i18next";
import { translateSelectors } from "helpers";
import * as Yup from "yup";
import { HelpOutline } from "@mui/icons-material";

export const CreationForm = ({
  values,
  setPassword,
  setOpen,
  setNewData,
  locations,
  canAdd = false,
  canChange = false,
  canDelete = false,
}) => {
  const { t } = useTranslation(["shiftAttendance"]["general"]);

  let validationShape = {
    password: Yup.string()
      .min(12, t("general:validation1"))
      .max(20, t("general:validation2"))
      .matches(/[a-zA-Z]/, t("general:validation3"))
      .matches(/\d/, t("general:validation4"))
      .matches(/[$@$!%*?&#]/, t("general:validation5"))
      .notOneOf(["123", "abc"], t("general:validation6"))
      .matches(/[a-z]/, t("general:validation7"))
      .matches(/[A-Z]/, t("general:validation8"))
      .matches(/\S/, t("general:validation9"))
      .required(t("general:validation10")),
    password2: Yup.string()
      .oneOf([Yup.ref("password"), null], t("general:validation11"))
      .required(t("general:validation12")),
    name: Yup.string()
      .matches(/^[a-zA-Z0-9_-]+$/, t("general:validation13"))
      .required(t("settings:isRequired")),
    type: Yup.string().required(t("settings:isRequired")),
    location: Yup.string().required(t("settings:isRequired")),
    sesionExpires: Yup.string().required(t("settings:isRequired")),
    description: Yup.string().required(t("settings:isRequired")),
  };

  if (values) {
    validationShape = {
      name: validationShape.name,
      type: validationShape.type,
      location: validationShape.location,
      sesionExpires: validationShape.sesionExpires,
      description: validationShape.description,
    };
  }

  const validationSchema = Yup.object().shape(validationShape);
  const readOnly = (!canAdd && !values) || (!canChange && !!values?._id);

  const onSubmit = async (_values) => {
    const { password2, ...values } = _values;
    const request = {
      method: values?._id ? "PUT" : "POST",
      data: values,
      t,
    };
    const data = await requestTimeCheck(request);

    if (data) {
      setOpen(false);
      setNewData((prev) => !prev);
    }
  };

  const deleteClock = async () => {
    const request = {
      method: "DELETE",
      data: values,
      t,
    };
    const data = await requestTimeCheck(request);

    if (data) {
      setOpen(false);
      setNewData((prev) => !prev);
    }
  };

  return (
    <Formik
      initialValues={
        values === undefined
          ? {
              name: "",
              type: "WEB",
              isActive: true,
              description: "",
              location: "",
              photo: false,
              changePIN: false,
              msg: "",
              sesionExpires: 0,
              password: "",
              password2: "",
              geolocation: false,
            }
          : values
      }
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {(formik) => (
        <Form id="timeCheck" autoComplete="off">
          <Grid container spacing={2} mb={2}>
            <Grid item={true} xs={12} md={4}>
              <UITextFast
                label={t("shiftAttendance:name")}
                name="name"
                disabled={readOnly || formik.isSubmitting}
                required={true}
              />
            </Grid>
            <Grid item={true} xs={12} md={8}>
              <UITextFast
                label={t("shiftAttendance:descripcion")}
                name="description"
                disabled={readOnly || formik.isSubmitting}
                required={true}
              />
            </Grid>
            <Grid item={true} xs={12} md={4}>
              <UISelectFast
                label={t("shiftAttendance:tipo")}
                name="type"
                options={translateSelectors(t, TIME_CHECK_TYPES)}
                required={true}
                disabled={readOnly || formik.isSubmitting}
              />
            </Grid>
            <Grid item={true} xs={12} md={4}>
              <UISelectFast
                label={t("shiftAttendance:Ubicacion")}
                name="location"
                options={locations}
                disabled={readOnly || formik.isSubmitting}
                required={true}
              />
            </Grid>
            <Grid item={true} xs={12} md={4}>
              <UITextFast
                type="number"
                label={t("shiftAttendance:sesionExpires")}
                name="sesionExpires"
                disabled={readOnly || formik.isSubmitting}
                required={true}
              />
            </Grid>
            {!values?._id && (
              <>
                <Grid item={true} xs={12} md={4}>
                  <UIPasswordFast
                    label={t("shiftAttendance:Password")}
                    name="password"
                    disabled={readOnly || formik.isSubmitting}
                    required={true}
                  />
                </Grid>
                <Grid item={true} xs={12} md={4}>
                  <UIPasswordFast
                    label={t("shiftAttendance:ConfirmPassword")}
                    name="password2"
                    disabled={readOnly || formik.isSubmitting}
                  />
                </Grid>
              </>
            )}
          </Grid>
          <Stack spacing={2} mt={2} direction={{ xs: "column", sm: "row" }}>
            <UISwitchFast
              label={
                <Tooltip
                  title={
                    <Typography>
                      {t("shiftAttendance:RequiredPhotoHelp")}
                    </Typography>
                  }
                >
                  <>
                    {t("shiftAttendance:RequiredPhoto")}{" "}
                    <HelpOutline fontSize="small" />
                  </>
                </Tooltip>
              }
              name="photo"
              disabled={readOnly || formik.isSubmitting}
            />
            <UISwitchFast
              label={
                <Tooltip
                  title={
                    <Typography>
                      {t("shiftAttendance:RequiredGeolocationHelp")}
                    </Typography>
                  }
                >
                  <>
                    {t("shiftAttendance:RequiredGeolocation")}{" "}
                    <HelpOutline fontSize="small" />
                  </>
                </Tooltip>
              }
              name="geolocation"
              disabled={readOnly || formik.isSubmitting}
            />
            <UISwitchFast
              label={
                <Tooltip
                  title={
                    <Typography>
                      {t("shiftAttendance:CanChangePINHelp")}
                    </Typography>
                  }
                >
                  <>
                    {t("shiftAttendance:CanChangePIN")}{" "}
                    <HelpOutline fontSize="small" />
                  </>
                </Tooltip>
              }
              name="changePIN"
              disabled={readOnly || formik.isSubmitting}
            />
          </Stack>
          <Stack spacing={2} mt={2}>
            <UIJoditEditor
              label={t("shiftAttendance:Mensaje")}
              name="msg"
              disabled={readOnly || formik.isSubmitting}
            />
            <UISwitchFast
              label={t("shiftAttendance:Activo")}
              name="isActive"
              disabled={readOnly || formik.isSubmitting}
            />
          </Stack>
          <Stack
            mt={2}
            direction={{ xs: "column", sm: "row" }}
            spacing={{ xs: 1, sm: 1, md: 1 }}
          >
            {!readOnly && (
              <UIButton
                type="submit"
                label={t("general:Guardar")}
                loading={formik.isSubmitting}
                fullWidth={false}
              />
            )}
            <UIButton
              onClick={() => {
                setOpen(false);
              }}
              label={t("general:Cancel")}
              loading={formik.isSubmitting}
              fullWidth={false}
            />
            {values?._id && (
              <>
                {canChange && (
                  <UIButton
                    onClick={() => setPassword(true)}
                    label={t("shiftAttendance:changePassword")}
                    loading={formik.isSubmitting}
                    fullWidth={false}
                  />
                )}
                {canDelete && (
                  <UIButton
                    onClick={deleteClock}
                    label={t("shiftAttendance:deleteClock")}
                    loading={formik.isSubmitting}
                    fullWidth={false}
                  />
                )}
              </>
            )}
          </Stack>
        </Form>
      )}
    </Formik>
  );
};
