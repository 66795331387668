import { useTranslation } from "react-i18next";
import { Toast, UIButton, UIDatePickerFast, UITextFast } from "components";
import { CircularProgress, Grid, Stack, Typography } from "@mui/material";
import { Form, Formik } from "formik";
import {
  getBalanceDaysVacations,
  getEmploymentInfo,
  getEmployeeInTimeClock,
  getFlowVacation,
  getHolidays,
  getSchedules,
  deleteScheduledPermissions,
  postScheduledPermissions,
  putScheduledPermissions,
} from "api";
import { useDispatch, useSelector } from "react-redux";
import { setUpdateStaffs } from "@redux/slices/recruitment";
import { setRequestUpdated } from "@redux/slices/requests";
import { authUserSelect } from "@redux/slices/authUser";

import * as Yup from "yup";
import dayjs from "dayjs";
import { useEffect, useState, useRef } from "react";
import _ from "lodash";

const relDaysWeek = {
  sunday: 0,
  monday: 1,
  tuesday: 2,
  wednesday: 3,
  thursday: 4,
  friday: 5,
  saturday: 6,
};

export const VacationRequest = ({
  onConfirmRef = null,
  setOpen = null,
  request = null,
  readOnly = false,
  saveInRedux = true,
  history = false,
}) => {
  const { t } = useTranslation(["request"]["general"]);
  const { user } = useSelector(authUserSelect);
  const values = request
    ? request
    : {
        status: "PENDING",
        type: "VACATION",
        comment: "",
        startDate: dayjs.tz().format("YYYY-MM-DD"),
        endDate: dayjs.tz().format("YYYY-MM-DD"),
      };

  const dispatch = useDispatch();
  readOnly = readOnly ? readOnly : request?.status === "PENDING";

  const [finish, setFinish] = useState(false);
  const [vacationSummary, setVacationSummary] = useState({
    totalDays: 10,
    requestedDays: null,
    remainingDays: null,
    requestedDates: [],
    canRequest: true,
  });
  const [flowVacation, setFlowVacation] = useState(null);
  const [schedule, setSchedule] = useState(null);
  const [holidays, setHolidays] = useState([]);
  const validationSchema = Yup.object().shape({
    startDate: Yup.date().test(
      "is-before-endDate",
      t("request:startDateTest"),
      function (value) {
        const { endDate } = this.parent;
        return (
          !endDate ||
          dayjs(value).isBefore(dayjs(endDate)) ||
          dayjs(value).isSame(dayjs(endDate))
        );
      },
    ),
  });

  useEffect(() => {
    const getData = async () => {
      try {
        const userId = request?._id ? request.applicant : user._id;
        const { data: employmentInfo } = await getEmploymentInfo(userId);
        const { data: dataVacationFlowSettings } = await getFlowVacation();
        const { data: dataSchedule } = await getSchedules();
        const { data: dataEmployeeTC } = await getEmployeeInTimeClock(userId);
        const { data: dataHolidays } = await getHolidays();
        const { data: balance } = await getBalanceDaysVacations(userId);
        let summary = _.cloneDeep(vacationSummary);
        summary.totalDays = balance.balance;

        const flowVacation = dataVacationFlowSettings.find(
          (v) => employmentInfo.vacationFlowSetting === v._id,
        );

        setFlowVacation(flowVacation);

        setSchedule(
          dataSchedule.find((s) => dataEmployeeTC.schedule === s._id),
        );

        const mapHolidays = dataHolidays
          .filter((h) =>
            flowVacation.extraRules.holidayCalendars.includes(h._id),
          )
          .reduce((previous, current) => {
            previous = _.merge(
              _.map(
                _.filter(current.holidays, ["isWorkday", false]),
                ({ date }) => dayjs.tz(date).format("YYYY-MM-DD"),
              ),
              previous,
            );
            return previous;
          }, []);

        setHolidays(mapHolidays);
        setVacationSummary(summary);

        setFinish(true);
      } catch (e) {
        console.log(e);
        Toast.fire({
          icon: "error",
          title: t("request:ErrorGetVacationInfo"),
        });
      }
    };

    getData();
  }, []);

  useEffect(() => {
    if (finish) {
      if (["PENDING", "REVIEW_REQUIRED"].includes(values.status)) {
        calculateVacations(values);
      } else {
        setVacationSummary({
          requestedDays: values.days,
          requestedDates: values.dates,
        });
      }
    }
  }, [finish]);

  const onSubmit = async (values) => {
    values.startDate = dayjs.tz(values.startDate).format("YYYY-MM-DDTHH:mm");
    values.endDate = dayjs.tz(values.endDate).format("YYYY-MM-DDTHH:mm");
    values.days = vacationSummary.requestedDays;
    values.dates = vacationSummary.requestedDates;

    let message = "";
    try {
      await validationSchema.validate(values, {
        abortEarly: false,
        context: values,
      });

      try {
        if (values._id) {
          const { data } = await putScheduledPermissions(values._id, values);
          message = t("request:ScheduledPermissionsSuccessfullyUpdate");
          if (saveInRedux) {
            dispatch(
              setRequestUpdated({
                type: "vacation",
                action: "update",
                data: data,
              }),
            );
          }
        } else {
          const { data } = await postScheduledPermissions(values);
          message = t("request:ScheduledPermissionsSuccessfullyCreated");
          if (saveInRedux) {
            dispatch(
              setRequestUpdated({
                type: "vacation",
                action: "add",
                data: data,
              }),
            );
          }
        }
        dispatch(setUpdateStaffs(true));
        setOpen(false);
        Toast.fire({ icon: "success", title: message });
      } catch (error) {
        console.log(error.response);
        if (error?.response?.data?.message) {
          Toast.fire({
            icon: "error",
            title: error.response.data.message,
          });
        }
        console.log("Error to submit form: ", error);
      }
    } catch (e) {
      Toast.fire({
        icon: "error",
        title: e.errors.join("; "),
      });
    }
  };

  const deleteItem = async (item) => {
    try {
      await deleteScheduledPermissions(item._id);
      dispatch(setUpdateStaffs(true));
      dispatch(
        setRequestUpdated({
          type: "vacation",
          action: "delete",
          data: item,
        }),
      );
      setOpen(false);
      Toast.fire({
        icon: "success",
        title: t("request:ScheduledPermissionsSuccessfullyDelete"),
      });
    } catch (err) {
      Toast.fire({
        icon: "error",
        title: t("request:ScheduledPermissionsErrorDelete"),
      });
    }
  };

  const calculateWorkWeek = ({ weeksElapsed, workWeeks }) => {
    let workWeek = 1;
    let count = 1;

    while (count <= weeksElapsed) {
      workWeek = workWeeks === workWeek ? 1 : workWeek + 1;
      count++;
    }

    return workWeek - 1;
  };

  const calculateVacations = ({ startDate, endDate }) => {
    let summary = _.cloneDeep(vacationSummary);
    let dStartDate = dayjs.tz(startDate);
    let dEndDate = dayjs.tz(endDate);
    let requestedDays = 0;
    let remainingDays = vacationSummary.totalDays;
    let requestedDates = [];
    let {
      allowExceedingBalance,
      accountForNonWorkingDays,
      accountForHolidays,
    } = flowVacation.extraRules;

    if (schedule.type === "ROTATING") {
      while (dEndDate.diff(dStartDate, "days") >= 0) {
        let startOfTheSchedule = dayjs.tz(schedule.startOfTheSchedule);
        let dayWeek = 0;

        dayWeek = startOfTheSchedule.day();
        if (dayWeek > 0) {
          startOfTheSchedule = startOfTheSchedule.subtract(dayWeek, "day");
        }

        let dayStartWeek = dEndDate;
        dayWeek = dEndDate.day();

        if (dayWeek > 0) {
          dayStartWeek = dayStartWeek.subtract(dayWeek, "day");
        }

        const weeksElapsed = dayStartWeek.diff(startOfTheSchedule, "w");

        const workWeek = calculateWorkWeek({
          workWeeks: schedule.weeks.length,
          weeksElapsed,
        });

        const daysWeek = Object.keys(schedule.weeks[workWeek]).map(
          (d) => relDaysWeek[d],
        );

        let discountDay = daysWeek.includes(dEndDate.day());
        discountDay = holidays.includes(dEndDate.format("YYYY-MM-DD"))
          ? false
          : discountDay;
        discountDay =
          accountForHolidays && holidays.includes(dEndDate.format("YYYY-MM-DD"))
            ? true
            : discountDay;
        discountDay = accountForNonWorkingDays ? true : discountDay;

        if (discountDay) {
          requestedDates.unshift(dEndDate.format("YYYY-MM-DD"));
          requestedDays++;
          remainingDays--;
        }

        dEndDate = dEndDate.subtract(1, "day");
      }
    } else {
      const daysWeek = Object.keys(schedule.weeks[0]).map(
        (d) => relDaysWeek[d],
      );

      while (dEndDate.diff(dStartDate, "days") >= 0) {
        let discountDay = daysWeek.includes(dEndDate.day());
        discountDay = holidays.includes(dEndDate.format("YYYY-MM-DD"))
          ? false
          : discountDay;
        discountDay =
          accountForHolidays && holidays.includes(dEndDate.format("YYYY-MM-DD"))
            ? true
            : discountDay;
        discountDay = accountForNonWorkingDays ? true : discountDay;

        if (discountDay) {
          requestedDates.unshift(dEndDate.format("YYYY-MM-DD"));
          requestedDays++;
          remainingDays--;
        }

        dEndDate = dEndDate.subtract(1, "day");
      }
    }

    summary.requestedDays = requestedDays;
    summary.remainingDays = remainingDays;
    summary.requestedDates = requestedDates;
    summary.canRequest = allowExceedingBalance
      ? true
      : vacationSummary.totalDays >= requestedDays;
    setVacationSummary(summary);
  };

  return finish ? (
    <>
      <Formik initialValues={values} onSubmit={onSubmit}>
        {(formik) => (
          <Form>
            <Grid container={true} spacing={2}>
              <Grid item={true} xs={12} md={4} lg={3}>
                <UIDatePickerFast
                  label={t("request:startDate")}
                  name="startDate"
                  disabled={formik.isSubmitting || readOnly}
                  minDate={dayjs.tz()}
                  onChange={(date) => {
                    const newStartDate = date
                      ? date.format("YYYY-MM-DD")
                      : date;
                    const endDate = dayjs.tz(formik.values.endDate);
                    let newEndDate = endDate.format("YYYY-MM-DD");

                    formik.setFieldValue("startDate", newStartDate);
                    if (endDate.diff(date, "day") < 0) {
                      newEndDate = newStartDate;
                      formik.setFieldValue("endDate", newEndDate);
                    }

                    calculateVacations({
                      startDate: newStartDate,
                      endDate: newEndDate,
                    });
                  }}
                />
              </Grid>
              <Grid item={true} xs={12} md={4} lg={3}>
                <UIDatePickerFast
                  label={t("request:endDate")}
                  name="endDate"
                  disabled={formik.isSubmitting || readOnly}
                  minDate={dayjs.tz(formik.values.startDate)}
                  onChange={(date) => {
                    const newEndDate = date ? date.format("YYYY-MM-DD") : date;

                    formik.setFieldValue("endDate", newEndDate);

                    calculateVacations({
                      startDate: formik.values.startDate,
                      endDate: newEndDate,
                    });
                  }}
                />
              </Grid>
              <Grid item={true} xs={12} md={8} lg={6}>
                <UITextFast
                  label={t("request:Comentario")}
                  required={false}
                  name={"comment"}
                  disabled={formik.isSubmitting || readOnly}
                />
              </Grid>
            </Grid>
            <Stack
              direction={{ xs: "column", sm: "row" }}
              spacing={{ xs: 1, sm: 1, md: 1 }}
              mt={2}
              alignItems="flex-start"
            >
              <Stack spacing={0} mt={2}>
                {!history && (
                  <Typography>
                    {t("request:VacationDaysAllowed")}
                    <Typography component="span" color="primary">
                      {vacationSummary.totalDays}
                    </Typography>
                  </Typography>
                )}
                <Typography>
                  {t("request:RequestedVacationDays")}
                  <Typography component="span" color="primary">
                    {vacationSummary.requestedDays}
                  </Typography>
                </Typography>
                {!history && (
                  <Typography>
                    {t("request:RemainingVacationDays")}
                    <Typography component="span" color="primary">
                      {vacationSummary.remainingDays}
                    </Typography>
                  </Typography>
                )}
                <Stack spacing={0}>
                  <Typography>{t("request:RequestedDayAre")}</Typography>
                  {vacationSummary.requestedDates.map((d) => (
                    <Typography key={d}>{dayjs.tz(d).format("LL")}</Typography>
                  ))}
                </Stack>
              </Stack>
              {!history && (
                <Stack spacing={0} mt={2}>
                  {flowVacation &&
                    flowVacation.extraRules.allowExceedingBalance && (
                      <Typography variant="subtitle2">
                        {t("request:HelpAllowExceedingBalance")}
                      </Typography>
                    )}
                  {flowVacation &&
                    flowVacation.extraRules.allowVacationAccrual && (
                      <Typography variant="subtitle2">
                        {t("request:HelpAllowVacationAcrual", {
                          maximumAccrualPeriodsDays:
                            flowVacation.extraRules.maximumAccrualPeriodsDays,
                        })}
                      </Typography>
                    )}
                  {flowVacation &&
                    flowVacation.extraRules.accountForNonWorkingDays && (
                      <Typography variant="subtitle2">
                        {t("request:HelpAccountForNonWorkingDays")}
                      </Typography>
                    )}
                  {flowVacation &&
                    flowVacation.extraRules.accountForHolidays && (
                      <Typography variant="subtitle2">
                        {t("request:HelpAccountForHolidays")}
                      </Typography>
                    )}
                </Stack>
              )}
            </Stack>
            {!readOnly && (
              <Stack
                mt={2}
                direction={{ xs: "column", sm: "row" }}
                spacing={{ xs: 1, sm: 1, md: 1 }}
              >
                <UIButton
                  type="submit"
                  label={
                    formik?.values?._id
                      ? t("general:Actualizar")
                      : ["PENDING", "REVIEW_REQUIRED"].includes(
                            formik.values?.status,
                          )
                        ? t("general:Request")
                        : t("general:Guardar")
                  }
                  loading={formik.isSubmitting}
                  disabled={!vacationSummary.canRequest}
                  fullWidth={false}
                />
                {formik.values?.status === "REVIEW_REQUIRED" && (
                  <UIButton
                    label={t("general:Eliminar")}
                    onClick={() => {
                      onConfirmRef.current = async () => {
                        formik.setSubmitting(true);
                        await deleteItem(formik.values);
                      };
                      setOpen(false);
                    }}
                    disabled={
                      formik.isSubmitting || !vacationSummary.canRequest
                    }
                    fullWidth={false}
                  />
                )}
              </Stack>
            )}
          </Form>
        )}
      </Formik>
    </>
  ) : (
    <Stack
      direction="row"
      justifyContent="center"
      alignItems="center"
      spacing={2}
    >
      <CircularProgress />
    </Stack>
  );
};
